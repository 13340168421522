import Cookies from 'js-cookie';

export const oneTrustConsentByCategory = category => {
  if (undefined !== Cookies.get('OptanonConsent')) {
    const OptanonConsent = Cookies.get('OptanonConsent').split('groups');

    if (undefined !== OptanonConsent[1]) {
      return OptanonConsent[1].indexOf(`${category}:1`) > -1;
    }
  }

  return false;
};
