import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './Accordion.module.scss';
import Item from './Item';

const Accordion = ({
  element,
  expanded,
  onClick,
  type,
  expandedElement,
  favorite,
  hasExpandedElement,
  sendMail,
}) => {
  const [elementExpanded, setElementExpanded] = useState(null);

  const styleWrapper = [styles.Wrapper];
  let icon = <FontAwesomeIcon icon="chevron-down" />;
  const iconWrapper = [styles.IconWrapper];

  if (expanded) {
    styleWrapper.push(styles.WrapperExpanded);
    icon = <FontAwesomeIcon icon="chevron-right" />;
    iconWrapper.push(styles.IconWrapperExpanded);
  }

  const expandedElementHandle = (element) => {
    if (element === elementExpanded) {
      element = null;
    }
    setElementExpanded(element);
    expandedElement(element);
  };

  let elementRender = null;

  useEffect(() => {
    setElementExpanded(null);
  }, [expanded]);

  useEffect(() => {
    setElementExpanded(hasExpandedElement);
  }, [hasExpandedElement]);

  if (expanded && element.data) {
    elementRender = (
      <>
        {
          element.data.map((elt, indexElement) => {
            const length = element.data.length;
            return (
              <Item
                key={elt.id}
                item={elt}
                type={type}
                expanded={elementExpanded && elementExpanded.id === elt.id}
                isLast={indexElement === length - 1}
                favorite={favorite}
                onClick={() => expandedElementHandle(elt)}
                sendMail={(item) => sendMail(item)}
              />
            );
          })
        }
      </>
    );
  }

  return (
    <>
      <div
        className={styleWrapper.join(' ')}
        onClick={() => onClick()}
        role="button"
        tabIndex="0"
      >
        <div className={iconWrapper.join(' ')}>
          {icon}
        </div>
        <div className={styles.Title}>
          <p>{element.title}</p>
        </div>
      </div>
      {elementRender}
    </>
  );
};

Accordion.defaultProps = {
  expanded: false,
  favorite: false,
  hasExpandedElement: null,
};

Accordion.propTypes = {
  element: PropTypes.shape().isRequired,
  expanded: PropTypes.bool,
  favorite: PropTypes.bool,
  hasExpandedElement: PropTypes.shape(),
  onClick: PropTypes.func.isRequired,
  expandedElement: PropTypes.func.isRequired,
  sendMail: PropTypes.func,
};

export default Accordion;
