import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { combineReducers } from 'redux';
import userSlice from './slices/user/user.slice';
import dataSlice from './slices/data/data.slice';
import newsSlice from './slices/news/news.slice';
import alertSlice from './slices/alert/alert.slice';

const persistConfig = {
  key: 'root',
  whitelist: ['dataSlice'],
  storage,
};

const reducer = combineReducers({ userSlice, dataSlice, newsSlice, alertSlice });
const persistedReducer = persistReducer(persistConfig, reducer);

const persistConfigSession = {
  key: 'root',
  storage: storageSession,
  whitelist: ['userSlice'],
};
const reducerSession = combineReducers({ userSlice });
const persistedReducerSession = persistReducer(persistConfigSession, reducerSession);
const storeSession = configureStore({
  reducer: persistedReducerSession,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
export const PersistorSession = persistStore(storeSession);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
export const Persistor = persistStore(store);
const defaultExports = {
  Persistor,
  PersistorSession,
  store,
  storeSession,
};
export default defaultExports;

