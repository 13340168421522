import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import LoginLogo from '../../Components/modules/login/Logo/LoginLogo';
import LoginForm from '../../Components/modules/login/Form/LoginForm';
import LoginInfo from '../../Components/modules/login/Info/LoginInfo';
import styles from './AuthScreen.module.scss';
import Image from '../../Components/ui/Image/Image';
import Loader from '../../Components/ui/Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  setLogsScreenView,
  setUserPropertyPartner,
  setUserPropertyId,
} from '../../helpers/analytics';
import Modal from '../../Components/ui/Modal/Modal';
import Button, { Variant } from '../../Components/ui/Button/Button';
import { injectOTScript } from '../../helpers/oneTrust';

const AuthScreen = ({
  loadParameters,
  decorativeImage,
  informativeImage,
  userInfo,
  submitAuthenticate,
  isLoading,
  updateDate,
  initUserLoader,
  clearData,
  donneesPersonnelle,
  informationsCookie,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [showPopup, setShowPopup] = useState(false);
  const [cookieContent, setCookieContent] = useState(null);

  useEffect(() => {
    setLogsScreenView('login');
    if (!userInfo) {
      setUserPropertyPartner('');
      setUserPropertyId('');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initUserLoader();
  }, [initUserLoader]);

  useEffect(() => {
    loadParameters();
  }, [loadParameters]);

  useEffect(() => {
    if (userInfo !== null && !isLoading) {
      history.push('/news');
    }
  }, [userInfo, history, isLoading]);

  useEffect(() => {
    if (location.pathname === '/donnees-personnelles') {
      setCookieContent(donneesPersonnelle);
      setShowPopup(true);
    }
    else if (location.pathname === '/informations-sur-les-cookies') {
      setCookieContent(informationsCookie);
      setShowPopup(true);
    } else {
      setCookieContent(null);
      setShowPopup(false);
    }
  }, [donneesPersonnelle, informationsCookie, location.pathname]);

  const submitAuthenticateHandler = (handle, captchaToken) => {
    if (handle !== userInfo?.partner?.approval_number) {
      clearData();
    }
    submitAuthenticate(handle, captchaToken);
  };

  const handleClose = () => {
    setShowPopup(false);
    history.push('/login');
  }

  const isRetrievedImage = (imageObject) => {
    return imageObject && imageObject['data'];
  };

  const display = () => {
    return isLoading ? <Loader /> : <LoginForm onSubmit={submitAuthenticateHandler} />;
  };

  // Button close popup in footer popup
  const modalActions = (
    <>
      <Button
        variant={Variant.light}
        onClick={() => handleClose()}
      >
        Fermer
      </Button>
    </>
  );

  const date = updateDate ? new Date(updateDate) : null;

  return (
    <div className={styles.Wrapper}>
      <div className={styles.LoginBackground}>
        {isRetrievedImage(decorativeImage) && (
          <Image
            src={`data:${decorativeImage.mimeType};base64,${decorativeImage.data}`}
            alt=""
          />
        )}
      </div>
      <div className={styles.LoginContent}>
        <LoginLogo informativeImageSrc={isRetrievedImage(informativeImage) && `data:${informativeImage.mimeType};base64,${informativeImage.data}`} />
       { display() }
        <LoginInfo />
        <div className={styles.UpdateDate}>
          { date && (
            <div className={styles.UpdateDate}>
              <p>Dernière mise à jour des données: {date.toLocaleString()}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.Footer}>
        <div>
          <div
            className={styles.Confidentialite}
            onClick={() =>{
              window.open('https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20pour%20Domofinance%2011042022.%20DOMOFINANCE%2017052022%20docx','_blank')
            }}
          >
            <FontAwesomeIcon icon="external-link" />
            Protection des données personnelles
          </div>
        </div>
        <div className={styles.NoSpace}>
          <div
            className={styles.Confidentialite}
            onClick={() => {
              history.push('/informations-sur-les-cookies');
              injectOTScript();
            }}
          >
            <FontAwesomeIcon icon="external-link" />
            Informations sur les cookies
          </div>
        </div>
        <div>
          <p className={styles.BuildVersion}>Version : {process.env.REACT_APP_BUILD_VERSION}</p>
        </div>
        <div className={styles.Ecofinancement}>
          <img
            src={require('../../assets/images/ecofinancement.png')}
            alt="ecofinancement"
            height="50px"
          />
        </div>
      </div>

      {showPopup && (
        <Modal
          show={showPopup}
          onHide={() => handleClose()}
          title={''}
          footer={modalActions}
        >
          <div dangerouslySetInnerHTML={{__html: cookieContent}}/>
        </Modal>
      )}
    </div>
  );
};

AuthScreen.defaultProps = {
  userInfo: null,
};

AuthScreen.propTypes = {
  submitAuthenticate: PropTypes.func.isRequired,
  loadParameters: PropTypes.func.isRequired,
  decorativeImage: PropTypes.shape().isRequired,
  informativeImage: PropTypes.shape().isRequired,
  userInfo: PropTypes.shape(),
  updateDate: PropTypes.number,
  initUserLoader: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  donneesPersonnelle: PropTypes.string.isRequired,
  informationsCookie: PropTypes.string.isRequired,
};

export default AuthScreen;
