import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: "https://f22319e70f51467e85443f882db20a42@o353935.ingest.sentry.io/5445112",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 0.5,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
