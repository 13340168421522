import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyleVariables from '../../../assets/StyleVariables';

export const Variant = {
  light: 'Light',
  full: 'Full',
  lightGrey: 'LightGrey',
  orange: 'Orange',
};

const Button = ({
  children,
  specifiedStyle,
  onClick,
  icon,
  iconWrapperColor,
  variant,
  disabled,
}) => {
  const classes = [styles.DefaultTheme];
  if (variant) {
    classes.push(styles[variant]);
  }

  return (
    <button
      type="button"
      className={classes.join(' ')}
      style={{...specifiedStyle}}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {icon &&
        <div
          className={styles.IconWrapper}
          style={{ backgroundColor: iconWrapperColor}}
        >
          <FontAwesomeIcon
            className={styles.Icon}
            icon={icon}
          />
        </div>
      }
    </button>
  );
};

Button.defaultProps = {
  onClick: () => {},
  specifiedStyle: null,
  icon: null,
  iconWrapperColor: StyleVariables.brand600,
  variant: "",
  disabled: false,
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  specifiedStyle: PropTypes.shape(),
  icon: PropTypes.string,
  iconWrapperColor: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
