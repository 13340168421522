import { connect } from 'react-redux';
import SimulatorFormScreen from '../../Screens/Simulator/SimulatorFormScreen';
import {
  dataLinkEquipmentLoanSelector,
  dataSimulatorSelector,
} from '../../store/slices/data/data.selectors';

const mapStateToProps = (state) => ({
  equipmentsLoans: dataLinkEquipmentLoanSelector(state),
  dataSimulator: dataSimulatorSelector(state),
});

const SimulationsFormScreenContainer = connect(mapStateToProps)(SimulatorFormScreen);

export default SimulationsFormScreenContainer;
