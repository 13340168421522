import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './NewsSectionLink.module.scss';

const NewsSectionLink = ({ children, onClick }) => {
  return (
    <div
      className={styles.Wrapper}
      onClick={() => onClick()}
    >
      <div className={styles.Decoy}></div>
      <div className={styles.ContentWrapper}>
        {children}
      </div>
      <div className={styles.IconWrapper}>
        <FontAwesomeIcon icon="arrow-right" />
      </div>
    </div>
  );
};

NewsSectionLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
};

export default NewsSectionLink;
