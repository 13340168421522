import { connect } from 'react-redux';
import CookieMenuScreen from '../../Screens/CookieMenu/CookieMenuScreen';
import { userInformationSelector } from '../../store/slices/user/user.selectors';
import { donneesPersonnellesSelector, informationsCookiesSelector, backHistorySelector } from '../../store/slices/data/data.selectors';

const mapStateToProps = state => {
  return ({
    userInfo: userInformationSelector(state),
    donneesPersonnelle : donneesPersonnellesSelector(state),
    informationsCookie: informationsCookiesSelector(state),
    backHistory: backHistorySelector(state),
  });
};


const OffersScreenContainer = connect(
  mapStateToProps,
  null,
)(CookieMenuScreen);

export default OffersScreenContainer;
