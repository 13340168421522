import { connect } from 'react-redux';
import FipenScreen from '../../Screens/Simulator/FipenScreen';
import { partnerInformationSelector } from '../../store/slices/user/user.selectors';
import { generateFipen } from '../../store/slices/data/data.thunks';
import { dataFileIsLoading } from '../../store/slices/data/data.selectors';

const mapStateToProps = (state) => ({
  dataUser: partnerInformationSelector(state),
  fileIsLoading: dataFileIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  generateFipen: (params) => {
    dispatch(generateFipen(params));
  },
});

const FipenScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FipenScreen);

export default FipenScreenContainer;
