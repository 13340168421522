import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './SimulationsScreen.module.scss';
import Menu from '../../Components/ui/Menu/Menu';
import Equipment from '../../Components/modules/simulations/Equipment/Equipment';
import Accordion from '../../Components/ui/Accordion/Accordion';
import Item from '../../Components/ui/Accordion/Item';
import Header from '../../Components/ui/Header/Header';
import Modal from '../../Components/ui/Modal/Modal.js';
import Button, { Variant } from '../../Components/ui/Button/Button';
import SimulatorForm from '../../Containers/screens/SimulatorFormScreen';
import Results from '../Simulator/ResultsScreen';
import FipenScreen from '../../Containers/screens/FipenScreen';
import FicomScreen from '../../Containers/screens/FicomScreen';
import StyleVariables from '../../assets/StyleVariables';
import SimulatorFile from '../../Components/modules/simulations/SimulatorFile/SimulatorFile';
import { setLogsScreenView, setLogsSelectContent } from '../../helpers/analytics';

const SimulationsScreen = ({ materials, favorites, errorAlert, simulatorFileLink }) => {
  const history = useHistory();
  const mediaQueryIsMobileOrTablet = window.matchMedia(`(max-width: ${StyleVariables.breakpointDesktop}px)`);
  const [isMobile, setIsMobile] = useState(mediaQueryIsMobileOrTablet.matches);
  const [materialExpanded, setMaterialExpanded] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showEquipment, setShowEquipment] = useState(false);
  const [fromDeeplink, setFromDeeplink] = useState(false);
  const [materialsGroupStyles, setMaterialsGroupStyles] = useState([styles.WrapperMaterialsGroup]);
  const [showResults, setShowResults] = useState(false);
  const [showFipenScreen, setShowFipenScreen] = useState(false);
  const [showFicomScreen, setShowFicomScreen] = useState(false);
  const [results, setResults] = useState(null);
  const [showFilePdf, setShowFilePdf] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const params = useParams();
  const idGroupment = params.idGroupment;
  const idMaterial = params.idMaterial;

  useEffect(() => {
    if (equipment) {
      let foundEquipment = null;
      materials.find((group) => {
        foundEquipment = group.data.find(mat => mat.id === equipment.id);
        if (foundEquipment) {
          setEquipment(foundEquipment);

          return true;
        }

        return false;
      });
    }
    // eslint-disable-next-line
  }, [materials]);

  useEffect(() => {
    setLogsScreenView('simulation_home');

    const fromOffers = query.get('fromOffers');
    if (idGroupment && !materialExpanded) {
      const foundGroupment = materials.find(group => group.id === idGroupment);
      if (foundGroupment) {
        setMaterialExpanded(foundGroupment);
      } else {
        errorAlert('Vous n\'êtes pas autorisé à visualiser ce groupement');
      }
    }

    if (idMaterial && !equipment) {
      let foundEquipment = null;
      materials.find((group) => {
        foundEquipment = group.data.find(mat => mat.id === idMaterial);
        if (foundEquipment) {
          setMaterialExpanded(group);
          setEquipment(foundEquipment);
          if (fromOffers || !foundEquipment.hasPopup) {
            setShowEquipment(true);
          } else {
            setFromDeeplink(true);
            setShowPopup(true);
          }

          if (fromOffers) {
            setFromDeeplink(true);
          }

          if (isMobile) {
            const newMaterialsGroupStyles = materialsGroupStyles.slice();
            newMaterialsGroupStyles.push(styles.HideMaterialGroup);
            setMaterialsGroupStyles(newMaterialsGroupStyles);
          }

          return true;
        }

        return false;
      });

      if (!foundEquipment) {
        errorAlert('Vous n\'êtes pas autorisé à visualiser ce matériel');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (equipment && showEquipment) {
      setScreenLogsInfo('simulation_offre');
      setScreenLogsInfo('simulation_alt');
      setLogsSelectContent(equipment.equipmentName, equipment.groupName, 'simulation_home');
    }
    // eslint-disable-next-line
  }, [equipment, showEquipment]);

  useEffect(() => {
    if (equipment && results && showResults) {
      setScreenLogsInfo('simulation_results', true);
    }
    // eslint-disable-next-line
  }, [results, showResults]);

  useEffect(() => {
    if (equipment && results && showFipenScreen) {
      setScreenLogsInfo('fipen_generator', true);
    }
    // eslint-disable-next-line
  }, [showFipenScreen]);

  useEffect(() => {
    if (equipment && results && showFicomScreen) {
      setScreenLogsInfo('commercial_generator', true);
    }
    // eslint-disable-next-line
  }, [showFicomScreen]);

  const setScreenLogsInfo = (name, setResultsInfo = false) => {
    setLogsScreenView(name, {
      product_family: equipment.groupName,
      material: equipment.equipmentName,
      ...getLogsResultsInfo(results, setResultsInfo),
    });
  };

  const getLogsResultsInfo = (results, setResultsInfo) => {
    return setResultsInfo ? {
      amount: results.amountAdjust,
      birthdayBorrower: results.birthdayInsurance,
      birthdayCoBorrower: results.birthdayCoInsurance,
      duration: results.duration,
      payment: results.monthly1,
      modality: results.scale,
      report: results.postpone ? 'Vrai' : 'Faux',
      insurance: results.insuranceBorrower,
      co_insurance: results.insuranceCoborrower,
    } : null;
  };

  const mediaQueryTest = (event) => {
    setIsMobile(event.matches);
    const newMaterialsGroupStyles = [styles.WrapperMaterialsGroup];
    if (equipment && event.matches) {
      newMaterialsGroupStyles.push(styles.HideMaterialGroup);
    }
    setMaterialsGroupStyles(newMaterialsGroupStyles);
  };

  mediaQueryIsMobileOrTablet.addListener(mediaQueryTest);

  const expandedMaterialHandle = (material) => {
    if (materialExpanded === material) {
      setMaterialExpanded(null);
    } else {
      setMaterialExpanded(material);
    }
  };

  const showEquipmentHandle = () => {
    setShowEquipment(true);
    setShowPopup(false);
    closeResults();
  };

  const closeModalHandle = () => {
    setShowPopup(false);
    setShowEquipment(false);
    setEquipment(null);
    closeResults();
  };

  const redirectToOffers = () => {
    history.push(`/offres/materiel/${idMaterial}`);
  };

  const modalActions = (
    <>
      <Button onClick={() => showEquipmentHandle()}>Accepter</Button>
      <Button
        variant={Variant.light}
        onClick={() => fromDeeplink ? redirectToOffers() : closeModalHandle()}
      >
        Refuser
      </Button>
    </>
  );

  const renderPopupContent = (equipment) => {
    const __html = equipment.popupContent;
    return { __html };
  };

  const expandedEquipment = (equipmentFromChildren, fromFavorite) => {
    if (fromDeeplink) {
      setFromDeeplink(false);
    }

    if (equipment === equipmentFromChildren) {
      equipmentFromChildren = null;
    }
    setEquipment(equipmentFromChildren);
    closeResults();
    closeFilePdf();
    closeFicomScreen();
    closeFipenScreen();
    closeResults();

    if (fromFavorite) {
      setMaterialExpanded(null);
    }

    if (equipmentFromChildren) {
      setShowEquipment(!equipmentFromChildren.hasPopup);
      setShowPopup(equipmentFromChildren.hasPopup);
      if (isMobile) {
        const newMaterialsGroupStyles = materialsGroupStyles.slice();
        newMaterialsGroupStyles.push(styles.HideMaterialGroup);
        setMaterialsGroupStyles(newMaterialsGroupStyles);
      }
    }
  };

  const closeSimulator = () => {
    if (fromDeeplink) {
      redirectToOffers();
    }

    const newMaterialsGroupStyles = [styles.WrapperMaterialsGroup];
    setMaterialsGroupStyles(newMaterialsGroupStyles);
    setEquipment(null);
    closeResults();
  };

  const renderResults = (results) => {
    setResults(results);
    setShowResults(true);
    setLogsSelectContent('simulation_validation', 'action', 'simulation_alt', {
      material: equipment.equipmentName,
      product_family: equipment.groupName,
      ...getLogsResultsInfo(results, true),
    });
  };

  const closeResults = () => {
    setShowResults(false);
    setResults(null);
  };

  const closeFipenScreen = () => {
    setShowFipenScreen(false);
  };

  const closeFicomScreen = () => {
    setShowFicomScreen(false);
  };

  const closeFilePdf = () => {
    setShowFilePdf(false);
  };

  const showFipenHandler = () => {
    setLogsSelectContent('fipen', 'conversion', 'simulation_results', {
      material: equipment.equipmentName,
      product_family: equipment.groupName,
      ...getLogsResultsInfo(results, true),
    });

    setShowFilePdf(true);
  };

  const showFicomHandler = () => {
    setLogsSelectContent('fiche_commerciale', 'conversion', 'simulation_results', {
      material: equipment.equipmentName,
      product_family: equipment.groupName,
      ...getLogsResultsInfo(results, true),
    });

    setShowFilePdf(true);
  };

  let favoriteRender = null;
  if (favorites) {
    favoriteRender = (
      <>
        {
          favorites.map((equipment) => {
            return (
              <Item
                key={equipment.id}
                item={equipment}
                favorite={true}
                type="material"
                isFromMenu={true}
                expanded={materialExpanded && materialExpanded.id === equipment.id}
                onClick={() => expandedEquipment(equipment, true)}
              />
            );
          })
        }
      </>
    );
  }

  let materialsGroup = (
    <div className={styles.NoMaterialsGroup}>Aucun matériel n'est validé pour votre compte, merci de contacter le pôle partenariat au 02 35 64 45 60</div>
  );

  if (materials && materials.length > 0) {
    materialsGroup = (
      <>
        {
          materials.map((material) => {
            return (
              <Accordion
                key={material.id}
                element={material}
                type="material"
                favorite={true}
                expanded={materialExpanded && material.id === materialExpanded.id}
                onClick={() => expandedMaterialHandle(material)}
                expandedElement={expandedEquipment}
                hasExpandedElement={equipment}
              />
            );
          })
        }
      </>
    );
  }

  const renderHeader = () => {
    let redirectClick = closeSimulator;

    if (fromDeeplink) {
      redirectClick = redirectToOffers;
    }

    if (showResults) {
      redirectClick = closeResults;
    }

    if (showFipenScreen) {
      redirectClick = closeFipenScreen;
    }

    if (showFicomScreen) {
      redirectClick = closeFicomScreen;
    }

    if (showFilePdf) {
      redirectClick = closeFilePdf;
    }

    return (
      <Header
        returnButton={(isMobile && equipment !== null) || showResults}
        fromComponent="simulation_home"
        onClick={() => redirectClick()}
      />
    );
  };

  const renderSimulatorElements = () => {
    return (
      <>
        <div className={showResults ? styles.HideSimulatorForm : ''}>
          <SimulatorForm
            equipment={equipment}
            showResults={(results) => renderResults(results)}
          />
        </div>
        {showResults && results && !showFipenScreen && !showFicomScreen && (
          <Results
            results={results}
            onClose={() => closeResults()}
            showFipenScreen={() => setShowFipenScreen(true)}
            showFicomScreen={() => setShowFicomScreen(true)}
            equipment={equipment}
          />
        )}
        {showFipenScreen && renderFipenElements()}
        {showFicomScreen && renderFicomElements()}
      </>
    );
  };

  const renderFipenElements = () => {
    return (
      <>
        <div className={showFilePdf ? styles.HideFipenScreen : ''}>
          <FipenScreen
            onClose={() => closeResults()}
            results={results}
            equipment={equipment}
            showFipenPdf={() => showFipenHandler()}
          />
        </div>
        {showFilePdf && simulatorFileLink && (
          <SimulatorFile
            title="Fipen"
            fileLink={simulatorFileLink}
          />
        )}
      </>
    );
  };

  const renderFicomElements = () => {
    return (
      <>
        <div className={showFilePdf ? styles.HideFipenScreen : ''}>
          <FicomScreen
            onClose={() => {}}
            results={results}
            equipment={equipment}
            showFicomPdf={() => showFicomHandler()}
          />
        </div>
        {showFilePdf && simulatorFileLink && (
          <SimulatorFile
            title="Fiche Commerciale"
            fileLink={simulatorFileLink}
          />
        )}
      </>
    );
  };

  return (
    <div className={styles.Wrapper}>
      {renderHeader()}
      <div className={materialsGroupStyles.join(' ')}>
        <Menu active="simulations" />
        <div className={styles.MaterialGroup}>
          {favoriteRender}
          {materialsGroup}
        </div>
      </div>
      <div className={styles.Equipment}>
        {equipment && showEquipment && (
          <Equipment equipment={equipment} />
        )}
      </div>
      <div className={styles.Simulator}>
        {equipment && showEquipment && renderSimulatorElements()}
      </div>

      {showPopup && (
        <Modal
          show={showPopup}
          onHide={() => fromDeeplink ? redirectToOffers() : closeModalHandle()}
          title={equipment.popupTitle}
          footer={modalActions}
        >
          <div dangerouslySetInnerHTML={renderPopupContent(equipment)} />
        </Modal>
      )}
    </div>
  );
};

SimulationsScreen.propTypes = {
  materials: PropTypes.array.isRequired,
  favorites: PropTypes.array.isRequired,
  errorAlert: PropTypes.func.isRequired,
  simulatorFileLink: PropTypes.string,
};

export default SimulationsScreen;
