import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styles from './InformationsScreen.module.scss';
import Menu from '../../Components/ui/Menu/Menu';
import Information from '../../Components/modules/informations/Information/Information';
import Accordion from '../../Components/ui/Accordion/Accordion';
import Header from '../../Components/ui/Header/Header';
import Modal from '../../Components/ui/Modal/Modal';
import Button from '../../Components/ui/Button/Button';
import StyleVariables from '../../assets/StyleVariables';
import EmailModal from '../../Components/ui/Modal/EmailModal';
import { setLogsScreenView, setLogsSelectContent } from '../../helpers/analytics';

const InformationsScreen = ({ informations, sendMailPdf }) => {
  const mediaQueryIsMobileOrTablet = window.matchMedia(`(max-width: ${StyleVariables.breakpointDesktop}px)`);
  const [isMobile, setIsMobile] = useState(mediaQueryIsMobileOrTablet.matches);

  const [categoryExpanded, setCategoryExpanded] = useState(null);
  const [information, setInformation] = useState(null);
  const [informationGroupStyles, setInformationGroupStyles] = useState([styles.WrapperInformationsGroup]);
  const [offlinePopup, setOfflinePopup] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [informationId, setInformationId] = useState(false);
  const [informationMail, setInformationMail] = useState(null);

  const params = useParams();
  useEffect(() => {
    setLogsScreenView('information');
    
    if (params.idCategory && !categoryExpanded) {
      const foundCategory = informations.find(group => group.id === params.idCategory);
      if (foundCategory) {
        setCategoryExpanded(foundCategory);
      }
    }

    if (params.idInfo && !information) {
      informations.find((category) => {
        const foundInformation = category.data.find(mat => mat.id === parseInt(params.idInfo));
        if (foundInformation) {
          setCategoryExpanded(category);
          setInformation(foundInformation);

          return true;
        }

        return false;
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (information) {
      setLogsScreenView('information_details', {
        content_name: information.title.replace(/(<([^>]+)>)/gi, ""),
        content_category: information.caterogyName,
      });
    }
  }, [information]);

  const mediaQueryTest = (event) => {
    setIsMobile(event.matches);
    const newInformationGroupStyles = [styles.WrapperInformationsGroup];
    if (information && event.matches) {
      newInformationGroupStyles.push(styles.HideInformationsGroup);
    }
    setInformationGroupStyles(newInformationGroupStyles);
  };
  mediaQueryIsMobileOrTablet.addListener(mediaQueryTest);

  const expandedCategoryHandle = (category) => {
    if (categoryExpanded === category) {
      setCategoryExpanded(null);
    } else {
      setCategoryExpanded(category);
    }
  };

  const expandedInformation = (information) => {
    setInformation(information);
    if (information) {
      setLogsSelectContent(information.title, 'info_utile', 'information', {
        content_name: information.title,
        content_category: information.caterogyName,
      });
      if (!window.navigator.onLine && information.type !== 'texte' && information.type !== 'redirection') {
        setOfflinePopup(true);
      }

      if (isMobile) {
        const newInformationGroupStyles = informationGroupStyles.slice();
        newInformationGroupStyles.push(styles.HideInformationsGroup);
        setInformationGroupStyles(newInformationGroupStyles);
      }
    }
  };

  const closeInformation = () => {
    const newInformationGroupStyles = [styles.WrapperInformationsGroup];
    setInformationGroupStyles(newInformationGroupStyles);
    setInformation(null);
  };

  const closeModalHandle = () => {
    setOfflinePopup(false);
    closeInformation();
  };

  const sendMailHandle = (item) => {
    if (!window.navigator.onLine) {
      setInformation(item);
      setOfflinePopup(true);
      return;
    }
    setInformationMail(item);
    setInformationId(item.id);
    setShowEmailModal(true);
    setLogsScreenView('information_email_popin', {
      content_name: item.title,
      content_category: item.caterogyName,
    });
  };

  const sendMailPdfHandler = (informationId, email) => {
    setLogsSelectContent(informationMail.title, 'envoyer', 'information_email_popin', {
      content_name: informationMail.title,
      content_category: informationMail.caterogyName,
    });
    sendMailPdf(informationId, email);
    setInformationMail(null);
  };

  const modalActions = (
    <Button onClick={() => closeModalHandle()}>
      J'ai compris
    </Button>
  );

  let informationsGroup = null;
  if (informations) {
    informationsGroup = (
      <>
        {
          informations.map((info) => {
            return (
              <Accordion
                key={info.id}
                element={info}
                type="information"
                expanded={categoryExpanded && info.id === categoryExpanded.id}
                onClick={() => expandedCategoryHandle(info)}
                expandedElement={expandedInformation}
                hasExpandedElement={information}
                sendMail={(item) => sendMailHandle(item)}
              />
            );
          })
        }
      </>
    );
  }

  return (
    <>
      <div className={styles.Wrapper}>
        {isMobile && information ? (
          <Header
            returnButton={true}
            onClick={() => closeInformation()}
          />
        ) : <Header />}
        <div className={informationGroupStyles.join(' ')}>
          <Menu active="informations"/>
          <div className={styles.InformationGroup}>
            {informationsGroup}
          </div>
        </div>

        {information && (
          <div className={styles.Information}>
            { offlinePopup && (
              <Modal
                show={true}
                onHide={() => closeModalHandle()}
                title="Information"
                footer={modalActions}
              >
                <p>Vous êtes actuellement en mode hors connexion, veuillez passer en mode connecté pour accéder à cette ressource"</p>
              </Modal>
            )}

            {!offlinePopup && (
              <Information
                information={information}
                onClose={closeInformation}
              />
            )}
          </div>
        )}
      </div>
      {showEmailModal && informationId && (
        <EmailModal
          onClose={() => setShowEmailModal(false)}
          onSubmit={(email) => sendMailPdfHandler(informationId, email)}
        />
      )}
    </>
  );
};

InformationsScreen.propTypes = {
  informations: PropTypes.array.isRequired,
  sendMailPdf: PropTypes.func.isRequired,
};

export default InformationsScreen;
