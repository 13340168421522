import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from '../../../assets/images/domofinance_logo.png';
import styles from './Header.module.scss';
import menuCookieImage from '../../../assets/images/menu-cookie.png'
import { setBackHistory } from '../../../store/slices/data/data.slice';
import { useDispatch } from 'react-redux';


const Header = ({ returnButton, onClick }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()

  // home button style
  const buttonHomeStyles = location.pathname === '/profile' ? styles.HomeMargin : styles.Home;

  const cookieMenuHandler = () =>{
    dispatch(setBackHistory(location.pathname));
    history.push('/profile');
  };

  let button = (
    <div className={styles.Decoy} />
  );

  if (returnButton) {
    button = (
      <button
        type="submit"
        className={buttonHomeStyles}
        onClick={onClick}
      >
          <span className={styles.ReturnButton}>
            <FontAwesomeIcon icon={['far', 'arrow-circle-left']} size='2x'/>
          </span>
      </button>
    );
  }

  // cookie menu button
  let cookieMenuButton = location.pathname !== '/login' && location.pathname !== '/profile' &&
  location.pathname !== '/donnees-personnelles' &&
  location.pathname !== '/informations-sur-les-cookies'
      ? (
          <button
              type="submit"
              className={styles.MenuCookieButton}
              onClick={cookieMenuHandler}
          >
              <span className={styles.HomeIcon}>
                <img
                  className={styles.MenuCookieImage}
                  src={menuCookieImage}
                  alt="MenuCookie" />
              </span>
          </button>
      )
      : null;

  return (
    <div className={styles.HeaderStyled}>
      {cookieMenuButton}
      {button}
      <div className={styles.HeaderLogout}>
        <img
          className={styles.ImgLogo}
          src={Logo}
          alt="Domosimu"
          onClick={() => history.push('/news')}
        />
      </div>
    </div>
  );
};

Header.defaultProps = {
  returnButton: false,
  fromComponent: '',
};

Header.propTypes = {
  returnButton: PropTypes.bool,
  onClick: PropTypes.func,
  fromComponent: PropTypes.string,
};


export default Header;
