import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    type: null,
    message: null,
    actionButton: false,
  },
  reducers: {
    successAlert(state, action) {
      state.type = 'alert-success';
      state.message = action.payload;
    },
    errorAlert(state, action) {
      state.type = 'alert-danger';
      state.message = action.payload;
    },
    clearAlert(state) {
      state.type = null;
      state.message = null;
      state.actionButton = false;
    },
    enableButtonActionAlert(state) {
      state.actionButton = true;
    },
  },
});

export const { successAlert, errorAlert, clearAlert, enableButtonActionAlert } = alertSlice.actions;

export default alertSlice.reducer;
