import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { oneTrustConsentByCategory } from './cookies';

export const setLogsSelectContent = (itemName, itemCategory, screenName, content) => {
  if (oneTrustConsentByCategory('C0002')) {
    firebase.analytics().logEvent('SELECT_CONTENT', {
      item_name: itemName,
      item_category: itemCategory,
      screen_name: screenName,
      platform: 'web',
      ...content,
    });
  }
};

export const setLogsScreenView = (screenName, content) => {
  if (oneTrustConsentByCategory('C0002')) {
    firebase.analytics().logEvent('SCREEN_VIEW', {
      screen_name: screenName,
      platform: 'web',
      ...content,
    });
  }
};

export const setUserPropertyId = (agrement) => {
  if (oneTrustConsentByCategory('C0002')) {
    firebase.analytics().setUserProperties({userid: agrement});
  }
};

export const setUserPropertyPartner = (companyName) => {
  if (oneTrustConsentByCategory('C0002')) {
    firebase.analytics().setUserProperties({partner: companyName});
  }
};
