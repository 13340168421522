import React from 'react';
import PropTypes from 'prop-types';
import styles from './LineResult.module.scss';

const LineResult = ({ children, chip, value }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Title}>
        {chip ? <span className={styles.Chip}>{chip}</span> : <span className={styles.NoChip} />}
        {children}
      </div>
      <div className={styles.Value}>{value}</div>
    </div>
  );
};

LineResult.propTypes = {
  children: PropTypes.shape().isRequired,
  chip: PropTypes.number,
  value: PropTypes.string.isRequired,
};

export default LineResult;
