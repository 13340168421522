import React from 'react';
import PropTypes from 'prop-types';
import DomofinanceLogo from '../../../../assets/images/domofinance_logo.png';
import styles from './LoginLogo.module.scss';

const LoginLogo = ({ informativeImageSrc }) => (
  <div className={styles.Wrapper}>
    <img
      className={styles.Logo}
      src={DomofinanceLogo}
      alt="Domofinance" />
    <div className={styles.Separator} />
    <div className={styles.InformativeBackground}>
      {informativeImageSrc &&
        <img
          src={informativeImageSrc}
          alt="information"
          className={styles.InformativeImage}
        />
      }
    </div>
  </div>
);

LoginLogo.defaultProps = {
  informativeImageSrc: null,
};

LoginLogo.propTypes = {
  informativeImageSrc: PropTypes.string,
};

export default LoginLogo;
