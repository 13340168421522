import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import DeepLinkRouter from '../../../Containers/components/modules/router/DeepLinkRouter';
import AuthScreen from '../../../Containers/screens/AuthScreen';
import NewsScreen from '../../../Containers/screens/NewsScreen';
import InformationsScreen from '../../../Containers/screens/InformationsScreen';
import OffersScreen from '../../../Containers/screens/OffersScreen';
import SimulationsScreen from '../../../Containers/screens/SimulationsScreen';
import CookieMenuScreen from '../../../Containers/screens/CookieMenuScreen';
import MaintenanceScreen from '../../../Screens/Maintenance/MaintenanceScreen';
import styles from './AppRouter.module.scss';
import Button from '../../../Components/ui/Button/Button';

const AppRouter = ({
   userInfo,
   alertInfo,
   clearAlert,
   updateDate,
   getInfos,
   getParameters,
   isMaintenance,
}) => {
  const isAuthenticated = userInfo !== null;

  const refreshData = () => {
    const dateToUpdate = Date.now() - (process.env.REACT_APP_INTERVAL_REFRESH_DATA * 1000);
    if (navigator.onLine && dateToUpdate > updateDate) {
      if (isAuthenticated) {
        const userId = userInfo.partner.approval_number;
        getInfos(userId);
      }
      getParameters();
    }
  }

  useEffect(() => {
    const refreshInterval = setInterval(refreshData, 60000);
    return function cleanup() {
      clearInterval(refreshInterval);
    };
    // eslint-disable-next-line
  }, [updateDate]);

  useEffect(() => {
    getParameters();
  }, [getParameters]);

  let snackbar = null;
  if (alertInfo.message) {
    const styleSnackbar = ['alert', alertInfo.type, styles.Snackbar];
    snackbar = (
      <div
        className={styleSnackbar.join(' ')}
        role="alert"
      >
        {alertInfo.message}
        {alertInfo.actionButton && (
          <Button
            onClick={clearAlert}
            specifiedStyle={{ padding: '5px 10px'}}
          >
            Ok
          </Button>
        )}
      </div>
    );

    if (!alertInfo.actionButton) {
      setTimeout(() => {
        clearAlert();
      }, 3000);
    }
  }

  if (isMaintenance) {
    return (
      <Router>
        <Switch>
          <Route component={MaintenanceScreen} />
        </Switch>
      </Router>
    );
  }

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            {isAuthenticated ? <NewsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/maintenance">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/ext" component={DeepLinkRouter} />
          <Route path="/simulations/groupement/:idGroupment">
            {isAuthenticated ? <SimulationsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route path="/simulations/materiel/:idMaterial">
            {isAuthenticated ? <SimulationsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/simulations">
            {isAuthenticated ? <SimulationsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route path="/informations/categorie/:idCategory">
            {isAuthenticated ? <InformationsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route path="/informations/information/:idInfo">
            {isAuthenticated ? <InformationsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/informations">
            {isAuthenticated ? <InformationsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route path="/offres/groupement/:idGroupment">
            {isAuthenticated ? <OffersScreen /> : <Redirect to="/login" />}
          </Route>
          <Route path="/offres/materiel/:idMaterial">
            {isAuthenticated ? <OffersScreen /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/offres">
            {isAuthenticated ? <OffersScreen /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/news">
            {isAuthenticated ? <NewsScreen /> : <Redirect to="/login" />}
          </Route>
          <Route path="/profile">
            {isAuthenticated ? <CookieMenuScreen /> : <Redirect to="/login" />}
          </Route>
          <Route path="/donnees-personnelles">
            {isAuthenticated ? <CookieMenuScreen /> : <AuthScreen />}
          </Route>
          <Route path="/informations-sur-les-cookies">
            {isAuthenticated ? <CookieMenuScreen /> : <AuthScreen />}
          </Route>
          <Route exact path="/login" component={AuthScreen} />
          <Route render={() => <h1>Not found</h1>} />
        </Switch>
      </Router>

      {snackbar}
    </>
  );
};

AppRouter.defaultProps = {
  userInfo: null,
  alertInfo: null,
};

AppRouter.propTypes = {
  userInfo: PropTypes.shape(),
  alertInfo: PropTypes.shape(),
  clearAlert: PropTypes.func.isRequired,
  updateDate: PropTypes.number,
  getInfos: PropTypes.func.isRequired,
  getParameters: PropTypes.func.isRequired,
  isMaintenance: PropTypes.bool.isRequired,
};

export default AppRouter;
