import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Menu.module.scss';

import MenuLink from './MenuLink';

const Menu = ({ active }) => {
  const history = useHistory();

  const openSimulationsHandler = () => {
    history.push('/simulations');
  };

  const openOffersHandler = () => {
    history.push('/offres');
  };

  const openInformationHandler = () => {
    history.push('/informations');
  };

  return (
    <>
      <div className={styles.WrapperMenu}>
        <MenuLink
          onClick={openSimulationsHandler}
          active={active === 'simulations'}
        >
          <div className={styles.IconWrapper}>
            <img
              src={require(`../../../assets/images/simulation${active !== 'simulations' ? '-brand' : ''}.svg`)}
              alt="simulations"
            />
          </div>
          <div className={styles.SectionTitle}>Simulation</div>
        </MenuLink>
        <MenuLink
          onClick={openInformationHandler}
          active={active === 'informations'}
        >
          <div className={styles.IconWrapper}>
            <img
              src={require(`../../../assets/images/information${active !== 'informations' ? '-brand' : ''}.svg`)}
              alt="informations"
            />
          </div>
          <div className={styles.SectionTitle}>Infos utiles</div>
        </MenuLink>
        <MenuLink
          onClick={openOffersHandler}
          active={active === 'offres'}
        >
          <div className={styles.IconWrapper}>
            <img
              src={require(`../../../assets/images/box${active !== 'offres' ? '-brand' : ''}.svg`)}
              alt="offres"
            />
          </div>
          <div className={styles.SectionTitle}>Offres</div>
        </MenuLink>
      </div>
    </>
  );
};

Menu.propTypes = {
  active: PropTypes.string.isRequired,
};

export default Menu;
