import React, { useState } from 'react';
import Button, { Variant } from '../Button/Button';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import styles from './EmailModal.module.scss';
import Modal from './Modal';
import validate from 'validate.js';

const EmailModal = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [error, setError] = useState(null);

  const constraints = {
    email: {
      email: {
        message: '^L\'email doit être une adresse email valide',
      },
    },
  };

  const submitAuthHandler = () => {
    onSubmit(email);
    onClose();
  };

  const handleChange = (text) => {
    setEmail(text.target.value);
    const emailError = validate({ email: text.target.value }, constraints);

    setValidForm(!emailError);
    setError(emailError);
  };

  const modalActions = (
    <>
      {navigator.onLine && (
        <Button
          disabled={!validForm}
          onClick={() => submitAuthHandler()}
        >
          Envoyer
        </Button>
      )}
      <Button
        variant={Variant.light}
        onClick={() => onClose()}
      >
        Annuler
      </Button>
    </>
  );

  let content = (
    <Form>
      <Form.Group>
        <Form.Label className="custom-label">Email</Form.Label>
        <Form.Control
          name="email"
          type="email"
          required
          placeholder="Saisissez le mail du destinataire"
          onChange={(text) => {handleChange(text)}}
        />
        {error && (
          <p className={styles.FormError}>{error.email[0]}</p>
        )}
      </Form.Group>
    </Form>
  );

  if (!navigator.onLine) {
    content = <p>Vous êtes actuellement en mode hors connexion, veuillez passer en mode connecté pour accéder à cette fonctionnalité</p>;
  }

  return (
    <Modal
      show={true}
      onHide={() => onClose()}
      title="Envoyer le fichier par mail"
      footer={modalActions}
    >
      {content}
    </Modal>
  );
};

EmailModal.propTypes = {
  onClose: PropTypes.func,
};

export default EmailModal;
