import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './OffersScreen.module.scss';

import Menu from '../../Components/ui/Menu/Menu';
import Accordion from '../../Components/ui/Accordion/Accordion';
import Equipment from '../../Components/modules/simulations/Equipment/Equipment';
import Header from '../../Components/ui/Header/Header';
import Modal from '../../Components/ui/Modal/Modal.js';
import Button, { Variant } from '../../Components/ui/Button/Button';
import StyleVariables from '../../assets/StyleVariables';
import { setLogsScreenView, setLogsSelectContent } from '../../helpers/analytics';

const OffersScreen = ({ materials, errorAlert }) => {
  const history = useHistory();
  const mediaQueryIsMobileOrTablet = window.matchMedia(`(max-width: ${StyleVariables.breakpointDesktop}px)`);
  const [isMobile, setIsMobile] = useState(mediaQueryIsMobileOrTablet.matches);
  const [materialExpanded, setMaterialExpanded] = useState(null);
  const [equipment, setEquipment] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [offersGroupStyles, setOffersGroupStyles] = useState([styles.WrapperMaterialsGroup]);

  const params = useParams();
  useEffect(() => {
    setLogsScreenView('offre_home');

    if (params.idGroupment && !materialExpanded) {
      const foundGroupment = materials.find(group => group.id === params.idGroupment);
      if (foundGroupment) {
        setMaterialExpanded(foundGroupment);
      } else {
        errorAlert('Vous n\'êtes pas autorisé à visualiser ce groupement');
      }
    }

    if (params.idMaterial && !equipment) {
      let foundEquipment = null;
      materials.find((group) => {
        foundEquipment = group.data.find(mat => mat.id === params.idMaterial);
        if (foundEquipment) {
          setMaterialExpanded(group);
          setEquipment(foundEquipment);

          if (isMobile) {
            const newOffersGroupStyles = offersGroupStyles.slice();
            newOffersGroupStyles.push(styles.HideOffersGroup);
            setOffersGroupStyles(newOffersGroupStyles);
          }

          return true;
        }

        return false;
      });

      if (!foundEquipment) {
        errorAlert('Vous n\'êtes pas autorisé à visualiser ce matériel');
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (equipment) {
      setLogsScreenView('offre_detail', {
        product_family: equipment.groupName,
        material: equipment.equipmentName,
      });
    }
    // eslint-disable-next-line
  }, [equipment]);

  const mediaQueryTest = (event) => {
    setIsMobile(event.matches);
    const newOffersGroupStyles = [styles.WrapperMaterialsGroup];
    if (equipment && event.matches) {
      newOffersGroupStyles.push(styles.HideOffersGroup);
    }
    setOffersGroupStyles(newOffersGroupStyles);
  };
  mediaQueryIsMobileOrTablet.addListener(mediaQueryTest);

  const expandedMaterialHandle = (material) => {
    if (materialExpanded === material) {
      setMaterialExpanded(null);
    } else {
      setMaterialExpanded(material);
      setLogsSelectContent(material.equipmentName, material.groupName, 'offre_home');
    }
  };

  const redirectToSimulation = () => {
    history.push(`/simulations/materiel/${equipment.id}?fromOffers=true`);
  };

  const modalActions = (
    <>
      <Button onClick={() => redirectToSimulation()}>Accepter</Button>
      <Button
        variant={Variant.light}
        onClick={() => setShowPopup(false)}
      >
        Refuser
      </Button>
    </>
  );

  const renderPopupContent = (equipment) => {
    const __html = equipment.popupContent;
    return { __html };
  };

  const openModal = () => {
    setLogsSelectContent('simulation_alt', 'action', 'offre_detail', {
      product_family: equipment.groupName,
      material: equipment.equipmentName,
    });

    if (equipment.hasPopup) {
      setShowPopup(true);
    } else {
      redirectToSimulation();
    }
  };

  const expandedEquipment = (equipmentFromChildren, fromFavorite) => {
    setEquipment(equipmentFromChildren);

    if (fromFavorite) {
      setMaterialExpanded(null);
    }
    if (equipmentFromChildren) {
      if (isMobile) {
        const newOffersGroupStyles = offersGroupStyles.slice();
        newOffersGroupStyles.push(styles.HideOffersGroup);
        setOffersGroupStyles(newOffersGroupStyles);
      }
    }
  };

  const closeOffer = () => {
    const newOffersGroupStyles = [styles.WrapperMaterialsGroup];
    setOffersGroupStyles(newOffersGroupStyles);
    setEquipment(null);
  };

  let materialsGroup = (
      <div className={styles.NoMaterialsGroup}>Aucun matériel n'est validé pour votre compte, merci de contacter le pôle partenariat au 02 35 64 45 60</div>
  );

  if (materials && materials.length > 0) {
    materialsGroup = (
      <>
        {
          materials.map((material) => {
            return (
              <Accordion
                key={material.id}
                element={material}
                type="material"
                expanded={materialExpanded && material.id === materialExpanded.id}
                onClick={() => expandedMaterialHandle(material)}
                expandedElement={expandedEquipment}
                hasExpandedElement={equipment}
              />
            );
          })
        }
      </>
    );
  }

  return (
    <div className={styles.Wrapper}>
      {isMobile && equipment ? (
        <Header
          returnButton={true}
          onClick={() => closeOffer()}
          fromComponent="offre_home"
        />
      ) : <Header fromComponent="offre_home" />}
      <div className={offersGroupStyles.join(' ')}>
        <Menu active="offres" />
        <div className={styles.MaterialGroup}>
          {materialsGroup}
        </div>
      </div>
      <div className={styles.Equipment}>
        {equipment && (
          <Equipment
            equipment={equipment}
            simulation={false}
            modalHandle={() => openModal()}
            closeMaterial={() => closeOffer()}
            isMobile={isMobile}
          />
        )}
      </div>

      {showPopup && (
        <Modal
          show={showPopup}
          onHide={() => setShowPopup(false)}
          title={equipment.popupTitle}
          footer={modalActions}
        >
          <div dangerouslySetInnerHTML={renderPopupContent(equipment)} />
        </Modal>
      )}
    </div>
  );
};

OffersScreen.propTypes = {
  materials: PropTypes.array.isRequired,
  errorAlert: PropTypes.func.isRequired,
};

export default OffersScreen;
