import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewsSectionLink from '../../Components/modules/news/SectionLink/NewsSectionLink';
import Carousel from '../../Components/modules/news/Carousel/Carousel';
import Header from '../../Components/ui/Header/Header';
import styles from './NewsScreen.module.scss';
import Information from '../../Components/modules/informations/Information/Information';
import StyleVariables from '../../assets/StyleVariables';
import { setLogsScreenView, setLogsSelectContent } from '../../helpers/analytics';
import noNewsImage from '../../assets/images/empty.png';
import Loader from '../../Components/ui/Loader/Loader';

const NewsScreen = ({ getData, news, isLoading }) => {
  const mediaQueryIsMobile = window.matchMedia(StyleVariables.mobile);
  const mediaQueryIsTablet = window.matchMedia(StyleVariables.tablet);
  const isMobile = mediaQueryIsMobile.matches;
  const isTablet = mediaQueryIsTablet.matches;
  let widthWrapperNews = StyleVariables.newsWrapperWidthMobileOrTablet;
  if (!isMobile && !isTablet) {
    widthWrapperNews = StyleVariables.newsWrapperWidthDesktop;
  }

  const history = useHistory();
  const [newInfo, setNewInfo] = useState(null);
  const [stylesWrapper, setStylesWrapper] = useState([styles.Wrapper]);
  const [nbNews, setNbNews] = useState(isMobile ? 1 : isTablet ? 1 : 1);

  useEffect(() => {
    setLogsScreenView('home');

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (newInfo) {
      setLogsScreenView('news', {
        content_name: newInfo.title.replace(/(<([^>]+)>)/gi, ""),
      });
    }
  }, [newInfo]);

  const updateWindowDimensions = () => {
    let windowWith = window.innerWidth;
    if (windowWith > StyleVariables.breakpointDesktop) {
      windowWith = windowWith - StyleVariables.widthDesktopLinksNews;
      widthWrapperNews = StyleVariables.newsWrapperWidthDesktop;
    }
    const nbNewsTemp = Math.floor(windowWith / widthWrapperNews);
    setNbNews(nbNewsTemp === 0 ? 1 : 1);
  };

  const openSimulationsHandler = () => {
    setContentMenuLogsInfo('simulation');
    history.push('/simulations');
  };

  const openOffersHandler = () => {
    setContentMenuLogsInfo('offres');
    history.push('/offres');
  };

  const openInformationHandler = () => {
    setContentMenuLogsInfo('informations_utiles');
    history.push('/informations');
  };

  const setContentMenuLogsInfo = (name) => {
    setLogsSelectContent(name, 'menu', 'home');
  };

  const showNews = (newInfo, index) => {
    setLogsSelectContent(newInfo.title.replace(/(<([^>]+)>)/gi, ""), 'slider', 'home', {
      item_position: index + 1,
    });

    setNewInfo(newInfo);
    const newStylesWrapper = stylesWrapper;
    newStylesWrapper.push(styles.HideWrapper);
    setStylesWrapper(newStylesWrapper);
  };

  const closeNewInfo = () => {
    setNewInfo(null);
    setStylesWrapper([styles.Wrapper]);
  };

  let carousel = (
    <Carousel
      news={news}
      onClick={(newInfo, index) => showNews(newInfo, index)}
      nbNews={nbNews}
    />
  );

  if (!navigator.onLine) {
    carousel = 'Vous êtes en mode hors ligne, les actualités ne peuvent pas être affichées. Veuillez vous connecter pour consulter les actualités.';
  }

  if (navigator.onLine && news.length === 0) {
    if (isLoading) {
      carousel = <Loader size="90" />;
    } else {
      carousel = (
        <div className={styles.NoNews}>
          <img
            src={noNewsImage}
            alt="noNews"
            className={styles.NoNewsImg}
          />
          <p>Aucune actualité pour le moment</p>
        </div>
      )
    }
  }

  return (
    <>
      {newInfo ? (
        <Header
          returnButton={true}
          onClick={() => closeNewInfo()}
        />
      ) : <Header />}
      <div className={stylesWrapper.join(' ')}>
        <div className={styles.LinksWrapper}>
          <NewsSectionLink onClick={openSimulationsHandler}>
            <div className={styles.IconWrapper}>
              <img
                className={styles.ButtonIcon}
                src={require('../../assets/images/simulation.svg')}
                alt="machine de calcul"
              />
            </div>
            <div className={styles.SectionTitle}>Simulation</div>
          </NewsSectionLink>
          <NewsSectionLink onClick={openInformationHandler}>
            <div className={styles.IconWrapper}>
              <img
                className={styles.ButtonIcon}
                src={require('../../assets/images/information.svg')}
                alt="information"
              />
            </div>
            <div className={styles.SectionTitle}>Infos utiles</div>
          </NewsSectionLink>
          <NewsSectionLink onClick={openOffersHandler}>
            <div className={styles.IconWrapper}>
              <img
                className={styles.ButtonIcon}
                src={require('../../assets/images/box.svg')}
                alt="offre"
              />
            </div>
            <div className={styles.SectionTitle}>Offres</div>
          </NewsSectionLink>
        </div>
        <div
          className={styles.CarouselWrapper}
          style={{
            maxWidth: '70vw',
          }}
        >
          {carousel}
        </div>
      </div>
      {newInfo && (
        <div className={styles.Information}>
          <Information
            information={newInfo}
            onClose={() => closeNewInfo()}
          />
        </div>
      )}
    </>
  );
};

NewsScreen.propTypes = {
  news: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
};

export default NewsScreen;
