import React from 'react';
import styles from './MaintenanceScreen.module.scss';

const MaintenanceScreen = () => {
  return (
    <img
      alt="maintenance"
      src={require('../../assets/images/maintenance.jpg')}
      className={styles.MaintenanceLogo}
    />
  );
};

export default MaintenanceScreen;
