export const injectOTScript = () => {
  return new Promise((resolve, reject) => {
    const element = document.getElementById("onetrust-pc-sdk");
    element.parentNode.removeChild(element);
    const script = document.createElement('script');
    script.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    script.setAttribute('data-document-language', 'true');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('data-domain-script', '%REACT_APP_ONETRUST_DATA_DOMAIN_SCRIPT%');
    script.addEventListener('load', resolve);
    script.addEventListener('error', e => reject(e.error));
    document.head.appendChild(script);
  });
};