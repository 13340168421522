export const userInformationSelector = (state) => {
  const sessionData = JSON.parse(sessionStorage.getItem('userSlice'));
  return sessionData ? sessionData.userInformation : state.userSlice.userInformation;
};

export const partnerInformationSelector = (state) => {
  const sessionData = JSON.parse(sessionStorage.getItem('userSlice'));
  return sessionData ? sessionData.userInformation?.partner : state.userSlice.userInformation?.partner;
};

export const userIsLoadingSelector = (state) => {
  const sessionData = JSON.parse(sessionStorage.getItem('userSlice'));
  return sessionData ? sessionData.isLoading : state.userSlice.isLoading;
};
