import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInformation: null,
    isLoading: false,
  },
  reducers: {
    setUserInformation(state, action) {
      Object.assign(state, { ...state, userInformation: action.payload });
    },
    setUserIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setUserInformation, setUserIsLoading } = userSlice.actions;

export default userSlice.reducer;
