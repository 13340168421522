import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { setLogsSelectContent } from '../../../../helpers/analytics';

const Favorite = ({ equipment, favoritesEquipments, addFavoriteMaterial, removeFavoriteMaterial }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  let favoriteIcon = (
    <img
      src={require(`../../../../assets/images/favorite-disabled.svg`)}
      alt="favorite"
      width="20px"
    />
  );

  if (isFavorite) {
    favoriteIcon = (
      <img
        src={require(`../../../../assets/images/favorite.svg`)}
        alt="favorite"
        width="20px"
      />
    );
  }

  useEffect(() => {
    const favorite = favoritesEquipments.includes(equipment.id);
    setIsFavorite(favorite);
  }, [equipment, favoritesEquipments]);

  const addFavoriteMaterialHandler = () => {
    setLogsSelectContent('favoris', equipment.equipmentName, 'simulation_home');
    addFavoriteMaterial(equipment);
  };

  const favoriteHandler = () => {
    isFavorite ? removeFavoriteMaterial(equipment) : addFavoriteMaterialHandler();
  }

  return (
    <div onClick={() => favoriteHandler()} >
      {favoriteIcon}
    </div>
  );
};

Favorite.propTypes = {
  equipment: PropTypes.shape().isRequired,
  favoritesEquipments: PropTypes.array.isRequired,
  addFavoriteMaterial: PropTypes.func.isRequired,
  removeFavoriteMaterial: PropTypes.func.isRequired,
};

export default Favorite;
