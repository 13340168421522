import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './FicomScreen.module.scss';
import SectionHeader from '../../Components/modules/simulations/SimulatorResults/SectionHeader';
import FormInput from '../../Components/ui/Form/FormInput';
import FormTextarea from '../../Components/ui/Form/FormTextarea';
import validate from 'validate.js';
import Button, { Variant } from '../../Components/ui/Button/Button';
import StyleVariables from '../../assets/StyleVariables';
import EmailModal from '../../Components/ui/Modal/EmailModal';
import { formatNumber } from '../../helpers/validation';
import Loader from '../../Components/ui/Loader/Loader';
import { setLogsSelectContent } from '../../helpers/analytics';
import { getMonth } from '../../helpers/simulator';

const FicomScreen = ({ dataUser, results, generateFicom, showFicomPdf, fileIsLoading, equipment }) => {
  const [companyName, setCompanyName] = useState(dataUser.company_name);
  const [rcsNumber, setRcsNumber] = useState(dataUser.no_scr);
  const [rcsCity, setRcsCity] = useState(dataUser.city_scr);
  const [address, setAddress] = useState(`${dataUser.address}\r\n${dataUser.zip_code} ${dataUser.city}`);
  const [amount, setAmount] = useState(null);
  const [project, setProject] = useState(results.equipmentName);
  const [errors, setErrors] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);

  const requiredError = '^Le champ est requis.';

  const constraints = {
    companyName: {
      presence: {
        message: requiredError,
        allowEmpty: false,
      },
    },
    rcsNumber: {
      presence: {
        message: requiredError,
        allowEmpty: false,
      },
      length: {
        is: 9,
        message: '^Le champ doit être composé de 9 chiffres.'
      },
    },
    rcsCity: {
      presence: {
        message: requiredError,
        allowEmpty: false,
      },
    },
    address: {
      presence: {
        message: requiredError,
        allowEmpty: false,
      },
    },
    amount: {
      presence: {
        message: requiredError,
        allowEmpty: false,
      },
      numericality: {
        greaterThanOrEqualTo: parseFloat(results.amountAdjust),
        message: `^Le champ doit être supérieur à ${results.amountAdjust}.`
      },
    },
    project: {
      presence: {
        message: requiredError,
        allowEmpty: false,
      },
      length: {
        maximum: 35,
        tooLong: '^Le champ est trop long, il doit être inférieur à 35 caractères maximum.'
      }
    },
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      validateData();
    }
    // eslint-disable-next-line
  }, [companyName, rcsNumber, rcsCity, address, amount, project]);

  const validateData = () => {
    const amountToValidate = amount ? parseFloat(amount.replace(/\s/g, '')) : '';
    const errors = validate({
      companyName,
      rcsNumber,
      rcsCity,
      address,
      amount: amountToValidate,
      project,
    }, constraints)

    setErrors(errors);

    return errors;
  };

  const getParams = (show, email = '') => {
    const borrowerBirthday = getMonth(results.birthdayBorrower)
    const coborrowerBirthday = getMonth(results.birthdayCoBorrower)
    const userQuery = [];
    userQuery[0] = {
      id: 1,
      query_amount: parseInt(results.amountAdjust),
      query_amount_retained: 0,
      query_borrower: results.insuranceBorrower,
      query_coborrower: results.insuranceCoborrower === 'Aucune' ? 'none' : results.insuranceCoborrower,
      query_coborrower_monthly_total: results.coborrowerTotalMonthly,
      query_date: "now",
      query_duration: results.duration,
      query_duration_credit: results.durationCreditContract,
      query_loan_monthly_total: results.loanTotalMonthly,
      query_material: results.equipmentName,
      query_type_equipment_id: results.typeEquipmentId,
      query_modalities: results.typeScaleId,
      query_monthly_payment1: results.monthly1,
      query_report: results.postpone,
      query_scale: results.scale,
      query_taeg: results.tegDisplay,
      query_total_credit: results.amountAdjust,
      user_details_id: dataUser.user_details_id,
      query_borrower_birthday: borrowerBirthday,
      query_coborrower_birthday: coborrowerBirthday,
    };

    const amountToValidate = amount ? parseFloat(amount.replace(/\s/g, '')) : '';
    const queryParams = {
      address: address,
      city_scr: rcsCity,
      company_name: companyName,
      comptant: amountToValidate,
      email_address: show ? '' : email,
      get_short_link_input: show ? 'checked' : '',
      id: dataUser.id,
      make_cc_input: '',
      no_scr: rcsNumber,
      receive_emails_input: show ? '' : 'checked',
      user_query: userQuery,
      project: project,
    };

    const date = new Date();
    const dateFormat = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.toLocaleTimeString('fr-FR')}`

    const simuLog = {
      datelog: dateFormat,
      approval: dataUser.approval_number,
      amount: results.amountAdjust,
    }

    return {
      fichecom_details: JSON.stringify(queryParams),
      jsondatatype: 1,
      simu_log: simuLog,
    };
  };

  const showFicom = () => {
    if (!validateData() && !fileIsLoading) {
      setLogsSelectContent('telecharger', 'conversion', 'commercial_generator', getLogsResultsInfo());
      if (project !== results.equipmentName) {
        setLogsSelectContent('modifier_nom_produit', 'action', 'commercial_generator', getLogsResultsInfo());
      }
      generateFicom(getParams(true));
      showFicomPdf();
    }
  };

  const showModalEmail = () => {
    if (!validateData() && !fileIsLoading) {
      setShowEmailModal(true);
    }
  };

  const sendMailFicom = (email) => {
    setLogsSelectContent('envoyer', 'conversion', 'commercial_generator', getLogsResultsInfo());
    if (project !== results.equipmentName) {
      setLogsSelectContent('modifier_nom_produit', 'action', 'commercial_generator', getLogsResultsInfo());
    }
    generateFicom(getParams(false, email));
  };

  const getLogsResultsInfo = () => {
    const borrowerBirthday = getMonth(results.birthdayBorrower)
    const coborrowerBirthday = getMonth(results.birthdayCoBorrower)
    return {
      material: equipment.equipmentName,
      product_family: equipment.groupName,
      amount: results.amountAdjust,
      duration: results.duration,
      payment: results.monthly1,
      modality: results.scale,
      report: results.postpone ? 'Vrai' : 'Faux',
      insurance: results.insuranceBorrower,
      co_insurance: results.insuranceCoborrower,
      birthdayBorrower: borrowerBirthday,
      birthdayCoBorrower: coborrowerBirthday,
    };
  };

  return (
    <div className={styles.Wrapper}>
      <SectionHeader>
        <h5>Génération de la Fiche Commerciale</h5>
      </SectionHeader>
      <p className={styles.InfosUser}>
        Pour que votre logo apparaisse sur la fiche et pour que vos données soient enregistrées, remplissez les champs correspondants dans « mon compte » sur votre espace partenaires <a href='https://partenaires.domofinance.com' target='_blank' rel='noopener noreferrer'>Domofinance</a>.
      </p>
      <div className={styles.FormFields}>
        <FormInput
          label="Nom de la société"
          type="text"
          placeholder="A renseigner"
          value={companyName}
          name="companyName"
          onChange={(value) => setCompanyName(value)}
          required={true}
          errorMessage={errors?.companyName}
        />
        <FormInput
          label="N° RCS"
          type="number"
          placeholder="A renseigner"
          value={rcsNumber}
          name="rcsNumber"
          onChange={(value) => setRcsNumber(value)}
          required={true}
          errorMessage={errors?.rcsNumber}
        />
        <FormInput
          label="Ville RCS"
          type="text"
          placeholder="A renseigner"
          value={rcsCity}
          name="rcsCity"
          onChange={(value) => setRcsCity(value)}
          required={true}
          errorMessage={errors?.rcsCity}
        />
        <FormTextarea
          label="Adresse du siège social"
          placeholder="A renseigner"
          value={address}
          name="address"
          onChange={(value) => setAddress(value)}
          required={true}
          errorMessage={errors?.address}
        />
        <FormInput
          label="Montant du devis"
          type="text"
          placeholder="A renseigner"
          value={amount}
          name="amount"
          onChange={(value) => setAmount(formatNumber(value))}
          required={true}
          errorMessage={errors?.amount}
        />
        <FormInput
          label="Projet"
          type="text"
          placeholder="A renseigner"
          value={project}
          name="Project"
          onChange={(value) => setProject(value)}
          required={true}
          errorMessage={errors?.project}
          maxLength={35}
        />

        <div className={styles.ButtonAction}>
          <Button
            onClick={() => showFicom()}
            variant={Variant.orange}
            icon='file-pdf'
            iconWrapperColor={!navigator.onLine ? StyleVariables.neutral500 : StyleVariables.brandAlt600}
            disabled={!navigator.onLine}
          >
            {fileIsLoading? <Loader size={20}/> : 'Afficher la Fiche Commerciale'}
          </Button>
          <Button
            onClick={() => showModalEmail()}
            variant={Variant.orange}
            icon='envelope'
            iconWrapperColor={!navigator.onLine ? StyleVariables.neutral500 : StyleVariables.brandAlt600}
            disabled={!navigator.onLine}
          >
            {fileIsLoading? <Loader size={20}/> : 'Envoyer par email'}
          </Button>
        </div>
      </div>
      {showEmailModal && (
        <EmailModal
          onClose={() => setShowEmailModal(false)}
          onSubmit={(email) => sendMailFicom(email)}
        />
      )}
    </div>
  );
};

FicomScreen.propTypes = {
  dataUser: PropTypes.shape().isRequired,
  results: PropTypes.shape().isRequired,
  generateFicom: PropTypes.func.isRequired,
  showFicomPdf: PropTypes.func.isRequired,
  fileIsLoading: PropTypes.bool.isRequired,
  equipment: PropTypes.shape().isRequired,
};

export default FicomScreen;
