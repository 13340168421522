import { connect } from 'react-redux';
import SimulationsScreen from '../../Screens/Simulations/SimulationsScreen';
import {
  dataSimulationsSelector,
  dataFavoritesEquipmentsSelector,
  dataSimulatorFileLinkSelector,
} from '../../store/slices/data/data.selectors';
import { errorAlert } from '../../store/slices/alert/alert.slice';

const mapStateToProps = (state) => {
  return ({
    materials: dataSimulationsSelector(state),
    favorites: dataFavoritesEquipmentsSelector(state),
    simulatorFileLink: dataSimulatorFileLinkSelector(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  errorAlert: (msg) => {
    dispatch(errorAlert(msg));
  },
});

const SimulationsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimulationsScreen);

export default SimulationsScreenContainer;
