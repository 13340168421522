import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormSelect.module.scss';

const FormSelect = ({
  title, elements, onClick, selectedElement,
}) => {
  const getStyleElement = (element) => (
    element === selectedElement
      ? [styles.Element, styles.SelectedElement]
      : [styles.Element]
  );

  const getStyleElementWrapper = (element) => (
    element === selectedElement
      ? [styles.ElementWrapper, styles.SelectedWrapper]
      : [styles.ElementWrapper]
  );

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.Elements}>
        {elements.map((element) => (
          <div
            key={element}
            className={getStyleElementWrapper(element).join(' ')}
            onClick={() => onClick(element)}
          >
            <div className={getStyleElement(element).join(' ')}>{element}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

FormSelect.defaultProps = {
  selectedElement: '',
};

FormSelect.propTypes = {
  title: PropTypes.string.isRequired,
  elements: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedElement: PropTypes.string,
};

export default FormSelect;
