import React from 'react';
import PropTypes from 'prop-types';
import styles from './CarouselButton.module.scss';
import Button from '../../../ui/Button/Button';
import StyleVariables from '../../../../assets/StyleVariables';

const CarouselButton = ({ type, onClick, bgColor, color }) => {
  let buttonLabel;
  switch (type) {
    case 'file':
      buttonLabel = 'Télécharger le PDF';
      break;
    case 'video':
      buttonLabel = 'Voir la vidéo';
      break;
    case 'redirect':
      buttonLabel = 'Aller à la page';
      break;
    default:
      buttonLabel = 'Lire la suite';
  }

  return (
    <div className={styles.ButtonWrapper}>
      <Button
        icon="arrow-right"
        specifiedStyle={{
          backgroundColor: bgColor,
          justifyContent: 'space-between',
          color: color,
        }}
        iconWrapperColor={StyleVariables.neutral300}
        onClick={() => onClick()}
      >
        {buttonLabel}
      </Button>
    </div>
  )
};

Button.defaultProps = {
  type: null,
  bgColor: '#e6e6e6',
  color: '#4d4d4d',
};

CarouselButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CarouselButton;
