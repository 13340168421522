export const preventNonNumericalInput = (e) => {
  e = e || window.event;
  const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  const charStr = String.fromCharCode(charCode);

  if (!charStr.match(/\d+/)) {
    e.preventDefault();
  }
};

export const formatNumber = (value) => {
  let newValue = value.toString();
  newValue = newValue.replace(/[\D\s._-]+/g, "");
  newValue = newValue ? parseInt( newValue, 10 ) : 0;

  return ( newValue === 0 ) ? "" : newValue.toLocaleString( "fr-FR" );
};