import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Equipment.module.scss';
import Favorite from '../../../../Containers/components/modules/simulations/Equipment/Favorite';
import Button from '../../../../Components/ui/Button/Button.js';
import { setLogsSelectContent } from '../../../../helpers/analytics';

const Equipment = ({ equipment, simulation, modalHandle }) => {
  const descriptionRender = () => {
    // Encapsulating <a> tags in <div> to display them properly
    let sanitizedContent = equipment.description;
    sanitizedContent = sanitizedContent.replace(/<a/gi, '<div><a');
    sanitizedContent = sanitizedContent.replace(/<\/a>/gi, '<div><span /></div></a></div>');
    const __html = sanitizedContent;
    return { __html };
  };

  useEffect(() => {
    setListener('proposer_financement');
    setListener('obtenir_financement');
    setListener('plus_de_detail');
    setListener('voir_video');
    // eslint-disable-next-line
  }, []);

  const setListener = (name) => {
    const items = document.getElementsByClassName(name);
    for (let item of items) {
      item.addEventListener('click', () => handleClick(name));
    }
  };

  const handleClick = (itemName) => {
    const screenName = simulation ? 'simulation_offre' : 'offre_detail';
    setLogsSelectContent(itemName, 'action', screenName, {
      product_family: equipment.groupName,
      material: equipment.equipmentName,
    });
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.WrapperHeader}>
        <div className={styles.Header}>
          <div className={styles.LogoWrapper}>
            {equipment.appicon && equipment.appsticker && (
              <img
                src={require(`../../../../assets/images/eqpstickers/${equipment.appsticker}`)}
                alt={equipment.appsticker}
                className={styles.Sticker}
              />
            )}
            {equipment.appicon && (
              <img
                src={require(`../../../../assets/images/eqpicons/${equipment.appicon}`)}
                alt={equipment.appicon}
                className={styles.Logo}
              />
            )}
          </div>
          <div className={styles.Title}>
            <p>{equipment.equipmentName}</p>
          </div>
        </div>
        {simulation && (
          <Favorite equipment={equipment} />
        )}
      </div>
      <div
        className={styles.Description}
      >
        <div dangerouslySetInnerHTML={descriptionRender()} />
        <p className={styles.Info}>Information non contractuelle</p>
        {!simulation && (
          <Button
            specifiedStyle={{
              padding: '10px',
              width: '100%',
              textTransform: 'uppercase',
            }}
            onClick={() => modalHandle()}
          >
            Simulation
          </Button>
        )}
      </div>
    </div>
  );
};

Equipment.defaultProps = {
  simulation: true,
};

Equipment.propTypes = {
  equipment: PropTypes.shape().isRequired,
  simulation: PropTypes.bool,
  modalHandle: PropTypes.func,
};

export default Equipment;
