import React from 'react';
import * as PropTypes from 'prop-types';
import styles from './GenerateFileInput.module.scss';
import FormElementWrapper from '../../../ui/Form/FormElementWrapper';

const GenerateFileInput = ({
  label,
  defaultValue,
  errorMessage,
  maxLength,
  value,
  placeholder,
  suffix,
  dynamicSuffix,
  required,
  onChange,
}) => (
  <FormElementWrapper>
    <div className={styles.Label}>{label} {required ? '*' : ''}</div>
    <div className={styles.InputWrapper}>
      <input
        placeholder={placeholder}
        className={styles.Input}
        defaultValue={defaultValue}
        maxLength={maxLength}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
      {suffix !== '' &&
        <div>
          <div className={styles.InputSignWrapper}>{suffix}</div>
        </div>
      }
      {dynamicSuffix !== '' && value !== '' &&
        <div>
          <div className={styles.InputSign}>{dynamicSuffix}</div>
        </div>
      }
    </div>
    {errorMessage !== '' && (<div className={styles.Error}>{errorMessage}</div>)}
  </FormElementWrapper>
);

GenerateFileInput.defaultProps = {
  defaultValue: '',
  maxLength: null,
  value: '',
  placeholder: 'A renseigner',
  suffix: '',
  dynamicSuffix: '',
  errorMessage: '',
  required: false,
};

GenerateFileInput.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  suffix: PropTypes.string,
  dynamicSuffix: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};
export default GenerateFileInput;
