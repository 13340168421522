import React, { useState } from 'react';
import Button from '../../../../Components/ui/Button/Button.js';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import styles from './LoginForm.module.scss';
import { setLogsSelectContent } from '../../../../helpers/analytics';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';

const LoginForm = ({ onSubmit }) => {
  const [handle, setHandle] = useState(null);

  const captchaRef = useRef(null)

  const submitAuthHandler = async (event) => {

    const token = captchaRef.current.getValue();

    setLogsSelectContent('connexion', 'connexion', 'login');

    event.preventDefault();
    event.stopPropagation();
    if (handle && token) {
      onSubmit(handle, token);
      captchaRef.current.reset();
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.InnerWrapper}>
        <Form onSubmit={submitAuthHandler}>
          <Form.Group>
            <Form.Label className="custom-label">Identifiant</Form.Label>
            <Form.Control
              type="text"
              placeholder="N° d'Agrément Partenaire"
              onChange={(text) => {
                setHandle(text.target.value);
              }}
            />
            <ReCAPTCHA
                className={styles.recaptcha}
                sitekey={process.env.REACT_APP_CAPTCHA_PUBLIC_KEY}
                ref={captchaRef}
            />
          </Form.Group>
        </Form>
        <Button
          onClick={submitAuthHandler}
          specifiedStyle={{
            display: 'block',
            width: '100%',
            padding: '10px',
          }}
        >
          Connexion
        </Button>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
