import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './Information.module.scss';

const Information = ({ information, onClose }) => {
  const history = useHistory();

  const displayContentFromType = () => {
    let html = '';

    if (information.type === 'texte') {
      html = information.text;
    }

    if (information.type === 'text') {
      html = information.content;
    }

    if (information.type === 'video') {
      const url = information.videoUrl ?? information.url;
      html = `
        <iframe
          width="100%"
          height="100%"
          src=${url}
          frameBorder="0"
        />
      `;
    }

    if (information.type === 'fichier') {
      let url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_BASE_URL}/info_content/${information.id}/file`;
      const fileNameInfos = information.pdfFileName.split('.');
      if (fileNameInfos[fileNameInfos.length - 1] === 'pdf') {
        html = `
          <iframe
            width="100%"
            src=${url} 
          />
        `;
      } else {
        url = `${url}?action=download`;
        window.open(
          url,
          '_blank',
          'location=yes,hideurlbar=yes,hidenavigationbuttons=yes,zoom=yes,enableViewportScale=yes'
        );
        onClose();
      }
    }

    if (information.type === 'file') {
      const url = `${process.env.REACT_APP_BASE_URL}${information.file.url}`;
      html = `
        <iframe
          width="100%"
          src=${url} 
        />
      `;
    }

    if (information.type === 'redirect' || information.type === 'redirection') {
      const redirectType = information.redirectType;
      const redirectTo = information.redirectTo;
      const redirectGroup = information?.redirectGroup?.id
      const redirectItem = information?.redirectItem?.id
      let group = false;
      let item = false;
      let url = '/';

      switch (redirectType) {
        case 'simulations':
          url += 'simulations';
          break;
        case 'simulations_group':
          url += 'simulations/groupement';
          group = true;
          break;
        case 'simulations_item':
          url += 'simulations/materiel';
          item = true;
          break;
        case 'offers':
          url += 'offres';
          break;
        case 'offers_group':
          url += 'offres/groupement';
          group = true;
          break;
        case 'offers_item':
          url += 'offres/materiel';
          item = true;
          break;
        case 'informations':
          url += 'informations';
          break;
        case 'informations_group':
          url += 'informations/categorie';
          group = true;
          break;
        case 'informations_item':
          url += 'informations/information';
          item = true;
          break;
        default:
          url = '/news';
          break;
      }

      if (url !== '/news') {
        if (redirectTo) {
          url += `/${redirectTo}`;
        }
        if (redirectGroup && group) {
          url += `/${redirectGroup}`;
        }
        if (redirectItem && item) {
          url += `/${redirectItem}`;
        }
      }

      history.push(url);
    }

    return <div dangerouslySetInnerHTML={{__html: html}}/>;
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.WrapperHeader}>
        <div className={styles.Header}>
          <div className={styles.Title}>
            <p>{information.title.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, '')}</p>
          </div>
        </div>
      </div>
      <div className={styles.Description}>
        {displayContentFromType()}
      </div>
    </div>
  );
};

Information.propTypes = {
  information: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Information;
