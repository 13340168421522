import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CookieMenuScreen.module.scss';
import Header from '../../Components/ui/Header/Header';
import roundUserAccountIcon from '../../assets/images/round-account-button-with-user-inside.png';
import rightChevronIcon from '../../assets/images/right-chevron.png';
import logoutImage from '../../assets/images/logout.png';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setLogsSelectContent } from '../../helpers/analytics';
import { injectOTScript } from '../../helpers/oneTrust';


const CookieMenuScreen = ({ userInfo, donneesPersonnelle, informationsCookie, backHistory }) => {
  const [cookieContent, setCookieContent] = useState(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/donnees-personnelles') {
      setCookieContent(donneesPersonnelle);
    }
    else if (location.pathname === '/informations-sur-les-cookies') {
      setCookieContent(informationsCookie);
    } else {
      setCookieContent(null);
    }
  }, [donneesPersonnelle, informationsCookie, location.pathname]);

  // Function when clicking on logout button
  const logoutHandler = () => {
    history.push('/ext');
  };

  // Function when clicking on return button
  const accueilHandler = () => {
    setLogsToHome('profile');
    history.push(backHistory);
  };

  // Home menu handler
  const homeHandler = () => {
    history.push('/news');
  };

  const setLogsToHome = (screenName) => {
    setLogsSelectContent('accueil', 'navigation', screenName);
  };

  // Logout button
  let logoutButton = location.pathname === '/profile' ||
  location.pathname === '/donnees-personnelles' ||
  location.pathname === '/informations-sur-les-cookies'
    ? (
      <button
        type="submit"
        className={styles.LogoutButton}
        onClick={logoutHandler}
      >
              <span>
                <img
                  className={styles.LogoutImage}
                  src={logoutImage}
                  alt="logout" />
              </span>
      </button>
    )
    : null;

  // Return button
  let returnButton = location.pathname === '/profile' ||
  location.pathname === '/donnees-personnelles' ||
  location.pathname === '/informations-sur-les-cookies'
    ? (
      <button
        type="submit"
        className={styles.HomeButton}
        onClick={accueilHandler}
      >
          <span className={styles.ReturnButton}>
            <FontAwesomeIcon icon={['far', 'arrow-circle-left']} size='2x'/>
          </span>
      </button>
    )
    : (
      <div className={styles.Decoy} />
    );

  // Adding user block
  const modalUser = (
      <div>
        <div className={styles.HeaderMenuCookie}>
          {returnButton}
          <img
            src={roundUserAccountIcon}
            alt={'user account'}
            className={styles.RoundAccountUserIcon}/>
          <h4 className={styles.WhiteText}> {userInfo.partner.company_name}</h4>
          {logoutButton}
        </div>
        <div>
          <p className={styles.ParagraphUser}>
            Pour modifier vos informations personnelles, remplissez les champs correspondants dans "mon compte" sur votre espace partenaires Domofinance.
          </p>
          <hr className={styles.WhiteLigne}/>
        </div>
      </div>
  );

  const navigationsCookie = (
    <>
      <div>
        <div onClick={() => homeHandler()} className={styles.FlexRow}>
          <h6 className={styles.WhiteText} > Retour à l'accueil</h6>
          <img src={rightChevronIcon} alt={''} className={styles.CheveronIcon}/>
        </div>
      </div>
      <div>
        <div onClick={() => {window.open('https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20pour%20Domofinance%2011042022.%20DOMOFINANCE%2017052022%20docx','_blank')
          }}
         className={styles.FlexRow}
        >
          <h6 className={styles.WhiteText} > Protection des données personnelles</h6>
          <img src={rightChevronIcon} alt={''} className={styles.CheveronIcon}/>
        </div>
      </div>
      <div>
        <div onClick={() => {
          history.push('/informations-sur-les-cookies');
          injectOTScript();
        }}
             className={styles.FlexRow}
        >
          <h6 className={styles.WhiteText} > Informations sur les cookies</h6>
          <img src={rightChevronIcon} alt={''} className={styles.CheveronIcon}/>
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.Wrapper}>
      <Header fromComponent="cookie-menu" />
      <div>
        <div className={styles.CookieGroup}>
          {/* Adding user info*/}
          {modalUser}
          {/* ADDING NAV ITEMS*/}
          {navigationsCookie}
        </div>
      </div>
      <div className={styles.Equipment}>
        <div dangerouslySetInnerHTML={{__html: cookieContent}}/>
      </div>
    </div>
  );
};

CookieMenuScreen.propTypes = {
  userInfo: PropTypes.object.isRequired,
  donneesPersonnelle: PropTypes.string.isRequired,
  informationsCookie: PropTypes.string.isRequired,
  backHistory: PropTypes.string.isRequired,
};

export default CookieMenuScreen;
