import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormTextarea.module.scss';

const FormTextarea = ({ label, placeholder, onChange, errorMessage, value, rows, required, name }) => (
  <div className={styles.Wrapper}>
    <div className={styles.Label}>{label}</div>
    <div className={styles.InputWrapper}>
      <textarea
        value={value}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        className={styles.Textarea}
        rows={rows}
        required={required}
        name={name}
      />
    </div>
    <div className={styles.Error}>{errorMessage}</div>
  </div>
);

FormTextarea.defaultProps = {
  name: '',
  placeholder: '',
  errorMessage: [],
  value: null,
  rows: 2,
  required: false,
};

FormTextarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.array,
  value: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
};

export default FormTextarea;
