let fetchParams = {
  method: 'GET',
  mode: 'cors',
  cache: 'default',
  headers: {}
};

const http = async (url, responseFormat, params = {}, is_return_body = false) => {
  if (params) {
    fetchParams = { ...fetchParams, ...params };
  }

  try {
    const dataStream = await fetch(url, fetchParams);
    if (fetchParams.method === 'POST') {
      if (is_return_body) {
        return dataStream[responseFormat]();
      }
      else {
        return dataStream.status;
      }
    }
    return dataStream[responseFormat]();
  } catch (error) {
    throw error;
  }
};

export { http };