import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import storeExports from './store/store';
import './assets/custom.scss';
import AppRouter from './Containers/components/modules/router/AppRouter';
import * as firebase from 'firebase/app';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { firebaseConfig } from './firebaseConfig';


library.add(fas);
library.add(far);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function App() {
  return (
        <Provider store={storeExports.store}>
          <PersistGate
            loading={null}
            persistor={storeExports.Persistor}
          >
            <AppRouter />
          </PersistGate>
        </Provider>
  );
}

export default App;
