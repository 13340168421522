import { createSlice } from '@reduxjs/toolkit';

const newsSlice = createSlice({
  name: 'news',
  initialState: {
    news: [],
    isLoading: false,
  },
  reducers: {
    setNews(state, action) {
      Object.assign(state, { ...state, news: action.payload })
    },
    setDataIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setNews, setDataIsLoading } = newsSlice.actions;

export default newsSlice.reducer;
