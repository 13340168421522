import { connect } from 'react-redux';
import Favorite from '../../../../../Components/modules/simulations/Equipment/Favorite';
import {
  addFavoriteMaterial,
  removeFavoriteMaterial,
} from '../../../../../store/slices/data/data.slice';
import {
  dataFavoritesEquipmentsIdSelector,
} from '../../../../../store/slices/data/data.selectors';

const mapStateToProps = (state) => {
  return ({
    favoritesEquipments: dataFavoritesEquipmentsIdSelector(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  addFavoriteMaterial: (equipment) => {
    dispatch(addFavoriteMaterial(equipment));
  },
  removeFavoriteMaterial: (equipment) => {
    dispatch(removeFavoriteMaterial(equipment));
  },
});

const FavoriteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Favorite);

export default FavoriteContainer;
