import { connect } from 'react-redux';
import { reloadUser } from '../../../../store/slices/user/user.thunks';
import {
  userInformationSelector,
} from '../../../../store/slices/user/user.selectors';
import {
  dataIsFetchSelector,
} from '../../../../store/slices/data/data.selectors';
import DeepLinkRouter from '../../../../Components/modules/router/DeepLinkRouter';
import {
  setUserInformation,
} from '../../../../store/slices/user/user.slice';
import { setDataJson, setDataIsFetch } from '../../../../store/slices/data/data.slice';

const mapStateToProps = (state) => ({
  userInfo: userInformationSelector(state),
  isFetch: dataIsFetchSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitAuthenticate: (handle) => {
    dispatch(reloadUser(handle));
  },
  logout: () => {
    dispatch(setUserInformation(null));
    dispatch(setDataJson(null));
    dispatch(setDataIsFetch());
  },
});

const DeepLinkRouterContainer = connect(mapStateToProps, mapDispatchToProps)(DeepLinkRouter);

export default DeepLinkRouterContainer;
