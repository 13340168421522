import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormInput.module.scss';
import { preventNonNumericalInput } from '../../../helpers/validation';

const FormInput = ({ label, placeholder, onChange, errorMessage, type, value, required, name, maxLength }) => {
  const isNumericalInput = type === 'number';

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Label}>{label}</div>
      <div className={styles.InputWrapper}>
        <input
          value={value ?? ''}
          type={type}
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
          className={styles.Input}
          required={required}
          name={name}
          onKeyPress={(event) => isNumericalInput ? preventNonNumericalInput(event) : {}}
          maxLength={maxLength}
        />
      </div>
      <div className={styles.Error}>{errorMessage}</div>
    </div>
  );
};

FormInput.defaultProps = {
  name: '',
  placeholder: '',
  errorMessage: [],
  type: 'text',
  value: '',
  required: false,
  maxLength: '',
};

FormInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.array,
  type: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default FormInput;
