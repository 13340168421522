import { connect } from 'react-redux';
import OffersScreen from '../../Screens/Offers/OffersScreen';
import {
  dataSimulationsSelector,
} from '../../store/slices/data/data.selectors';
import { errorAlert } from '../../store/slices/alert/alert.slice';

const mapStateToProps = (state) => {
  return ({
    materials: dataSimulationsSelector(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  errorAlert: (msg) => {
    dispatch(errorAlert(msg));
  },
});

const OffersScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OffersScreen);

export default OffersScreenContainer;
