export const appDecorativeImageSelector = (state) => {
  return state.dataSlice.parameters?.decorativeImage?.data ? state.dataSlice.parameters.decorativeImage : {};
};

export const appInformativeImageSelector = (state) => {
  return state.dataSlice.parameters?.informativeImage?.data ? state.dataSlice.parameters.informativeImage : {};
};

export const donneesPersonnellesSelector = (state) => {
  return state.dataSlice.parameters?.dataProtectionInformation? state.dataSlice.parameters.dataProtectionInformation : {};
};

export const informationsCookiesSelector = (state) => {
  return state.dataSlice.parameters?.cookiesInformation? state.dataSlice.parameters.cookiesInformation : {};
};

export const backHistorySelector = (state) => {
  return state.dataSlice.backHistory;
};

export const isMaintenanceSelector = (state) => {
  return state.dataSlice.parameters?.maintenanceDomoSimu ? state.dataSlice.parameters.maintenanceDomoSimu : false;
};

export const dataSimulationsSelector = (state) => {
  const dataRoot = state.dataSlice.json?.data;

  const filterTypeEquipmentGroup = dataRoot?.typeEquipmentGroup.filter(equipmentGroup => equipmentGroup.equipments.length > 0);

  return dataRoot ? filterTypeEquipmentGroup.map((group) => ({
    id: group.id,
    title: group.groupname,
    data: (group.equipments
      .map((equipment, index) => ({
        ...equipment,
        index,
        groupName: group.groupname,
      }))
    ),
  })) : [];
};

export const dataInformationsSelector = (state) => {
  const dataRoot = state.dataSlice.json?.data;

  return dataRoot ? dataRoot.info.map((group) => ({
    id: group.id,
    title: group.title,
    data: (group.infoContents
      .map((content, index) => ({
        ...content,
        index,
        caterogyName: group.title,
      }))
    ),
  })) : [];
};

export const dataFavoritesEquipmentsIdSelector = (state) => {
  const dataRoot = state.dataSlice.favoritesMaterials;

  return dataRoot.map((equipment) => equipment.id);
};

export const dataFavoritesEquipmentsSelector = (state) => state.dataSlice.favoritesMaterials;

export const updateDateSelector = (state) => state.dataSlice.updateDate;

export const dataIsFetchSelector = (state) => state.dataSlice.isFetch;

export const dataLinkEquipmentLoanSelector = (state) => {
  const dataRoot = state.dataSlice?.json?.data;

  return dataRoot ? dataRoot.linkEquipmentLoan.map((link) => {
    const typeLoan = dataRoot.typeLoan.find((loan) => loan.id === link.typeLoan.id);
    const typeScale = dataRoot.typeScale.find((scale) => scale.id === link.typeScale.id);
    const loanDurationStep = dataRoot.loanDurationStep.filter((duration) => duration?.typeLoan?.id === link.typeLoan.id);
    const loanAmountStep = dataRoot.loanAmountStep.filter((amount) => amount.typeLoan?.id === link.typeLoan.id);

    return {
      id: link.id,
      postpone: link.postpone,
      scale: link.scale,
      typeEquipmentId: link.typeEquipment.id,
      typeLoan: typeLoan,
      typeScale: typeScale,
      loanDurationStep: loanDurationStep,
      loanAmountStep: loanAmountStep,
    };
  }) : [];
};

export const dataSimulatorSelector = (state) => {
  const dataRoot = state.dataSlice?.json?.data;

  return {
    insuranceAmountRange: dataRoot.insuranceAmountRange,
    insuranceAgeRange: dataRoot.insuranceAgeRange,
    insuranceDurationRange: dataRoot.insuranceDurationRange,
    insuranceGridCell: dataRoot.insuranceGridCell,
    linkCoefValue: dataRoot.linkCoefValue,
  };
};

export const dataSimulatorFileLinkSelector = (state) => {
  return state.dataSlice?.simulatorFileLink;
};

export const dataFileIsLoading = (state) => {
  return state.dataSlice?.fileIsLoading;
};
