import React, { useEffect, useState } from 'react';
import {
  Redirect, useLocation, useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from '../../ui/Loader/Loader';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DeepLinkRouter = ({ userInfo, isFetch, submitAuthenticate, logout }) => {
  const [fetch, setFetch] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const key = query.get('agrement');
  const target = query.get('target') ? query.get('target') : '/news';

  const redirectTarget = (target) => {
    history.push(target);
  };

  useEffect(() => {
    if (userInfo?.partner?.approval_number === key) {
      redirectTarget(target);
    } else {
      if (key !== null) {
        submitAuthenticate(key);
      }
      setFetch(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fetch && userInfo !== null) {
      setFetch(false);
      redirectTarget(target);
    }
    if (fetch && userInfo === null) {
      setFetch(false);
      redirectTarget('/login');
    }
    // eslint-disable-next-line
  }, [isFetch]);

  let render = <Loader size="90"/>;
  if (key === null) {
    logout();
    render = <Redirect to="/login" />;
  }

  return (
    render
  );
};

DeepLinkRouter.propTypes = {
  submitAuthenticate: PropTypes.func.isRequired,
  userInfo: PropTypes.shape(),
  isFetch: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

export default DeepLinkRouter;
