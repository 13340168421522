import React from 'react';
import PropTypes from 'prop-types';
import styles from './ResultsScreen.module.scss';
import { numberFormat } from '../../helpers/simulator';
import SectionHeader from '../../Components/modules/simulations/SimulatorResults/SectionHeader';
import LineResult from '../../Components/modules/simulations/SimulatorResults/LineResult';
import Button, { Variant } from '../../Components/ui/Button/Button';
import StyleVariables from '../../assets/StyleVariables';
import { setLogsSelectContent } from '../../helpers/analytics';

const ResultsScreen = ({ results, onClose, showFipenScreen, showFicomScreen, equipment }) => {
  const textResultsHeader = () => {
    let txt = `Mensualité totale avec `;

    if (results.insuranceCoborrower !== 'Aucune') {
      txt += `assurances ${results.insuranceBorrower} et ${results.insuranceCoborrower}`;
    } else {
      txt += `assurance ${results.insuranceBorrower}`;
    }

    if (results.scale !== 'normal') {
      txt += `, modalité ${results.scale}`;
    }

    if (results.postpone) {
      txt += `, report 180 jours`;
    }

    txt += ' :';

    return txt;
  };

  const redoHandler = () => {
    setLogsSelectContent('recommencer', 'action', 'simulation_results', {
      material: equipment.equipmentName,
      product_family: equipment.groupName,
      amount: results.amountAdjust,
      duration: results.duration,
      payment: results.monthly1,
      modality: results.scale,
      report: results.postpone ? 'Vrai' : 'Faux',
      insurance: results.insuranceBorrower,
      co_insurance: results.insuranceCoborrower,
    });
    onClose();
  };

  const renderCoborrowerResults = () => (
    <div className={styles.InsuranceCoborrower}>
      <SectionHeader specifiedStyle={{ margin: '5px 10px' }}>
        <h5>Assurance co-emprunteur : {results.insuranceCoborrower}</h5>
      </SectionHeader>
      <LineResult
        chip={13}
        value={`${numberFormat(results.coborrowerLoanTotalInsuranceOptional)} €`}
      >
        <p>Coût total de l'assurance facultative</p>
      </LineResult>
      <LineResult
        chip={14}
        value={`${numberFormat(results.coborrowerTotalMonthly)} €`}
      >
        <p>Montant total mensuel</p>
      </LineResult>
    </div>
  );

  const renderRetainedValue = () => {
    if (results.scale !== 'normal') {
      return (
        <>
          <SectionHeader specifiedStyle={{ margin: '5px 10px' }}>
            <h5>Retenue vendeur</h5>
          </SectionHeader>
          <LineResult value={`${numberFormat(results.amountRetainedSeller)} €`} >
            <p>Montant retenue vendeur</p>
          </LineResult>
          <LineResult value={`${numberFormat(results.retainedCoef)} %`} >
            <p>Pourcentage de retenue</p>
          </LineResult>
        </>
      );
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>{equipment.equipmentName}</div>
      <div className={styles.Results}>
        <SectionHeader specifiedStyle={{ margin: '5px 10px' }}>
          <h5>{textResultsHeader()}</h5>
          <p>Les {results.duration} mois : {numberFormat(results.monthly1InsLin)} € / mois</p>
        </SectionHeader>
        <LineResult
          chip={1}
          value={`${numberFormat(results.amountAdjust)} €`}
        >
          <p>Montant total du crédit</p>
        </LineResult>
        <LineResult
          chip={2}
          value={`${results.durationCreditContract} mois`}
        >
          <p>Durée du contrat de crédit</p>
        </LineResult>
        <LineResult
          chip={3}
          value={`${results.duration} mois - ${numberFormat(results.monthly1)} €`}
        >
          <p>Echéances</p>
        </LineResult>
        <LineResult
          chip={5}
          value={`${results.firstDeadline} jours`}
        >
          <p>Première échéance à</p>
        </LineResult>
        <LineResult
          chip={6}
          value={results.scale === 'gratuit' ? 'V' : 'I'}
        >
          <p>Intérêts inclus dans les échéances</p>
        </LineResult>
        <LineResult
          chip={7}
          value={`${numberFormat(results.dossierFees)} €`}
        >
          <p>Frais de dossier</p>
        </LineResult>
        <LineResult
          chip={8}
          value={`${numberFormat(results.amountBorrower)} €`}
        >
          <p>Montant total dû</p>
        </LineResult>
        <LineResult value={results.equipmentName}>
          <p>Destination du crédit</p>
        </LineResult>
        <LineResult
          chip={9}
          value={`${numberFormat(results.tncDisplay)} %`}
        >
          <p>Taux débiteur</p>
        </LineResult>
        <LineResult
          chip={10}
          value={`${numberFormat(results.tegDisplay)} %`}
        >
          <p>TAEG</p>
        </LineResult>

        <div className={styles.Insurances}>
          <div className={styles.InsuranceBorrower}>
            <SectionHeader specifiedStyle={{ margin: '5px 10px' }}>
              <h5>Assurance emprunteur : {results.insuranceBorrower}</h5>
            </SectionHeader>
            <LineResult
              chip={11}
              value={`${numberFormat(results.loanTotalInsuranceOptional)} €`}
            >
              <p>Coût total de l'assurance facultative</p>
            </LineResult>
            <LineResult
              chip={12}
              value={`${numberFormat(results.loanTotalMonthly)} €`}
            >
              <p>Montant total mensuel</p>
            </LineResult>
          </div>

          {results.insuranceCoborrower !== 'Aucune' && renderCoborrowerResults()}
        </div>

        <LineResult
          chip={15}
          value={`${numberFormat(results.taea)} %`}
        >
          <p>TAEA</p>
        </LineResult>
        {renderRetainedValue()}
        <div className={styles.Info}>Simulation non contractuelle</div>

        <div className={styles.Button}>
          <Button
            onClick={() => redoHandler()}
            variant={Variant.full}
            specifiedStyle={{
              fontSize: '16px',
            }}
            icon='redo'
            iconWrapperColor={StyleVariables.brandHover}
          >
            Recommencer
          </Button>
          <div className={styles.ButtonAction}>
            <Button
              onClick={() => showFipenScreen()}
              variant={Variant.orange}
              specifiedStyle={{
                marginRight: '5px',
                fontSize: '16px',
              }}
              icon='file-alt'
              iconWrapperColor={!navigator.onLine ? StyleVariables.neutral500 : StyleVariables.brandAlt600}
              disabled={!navigator.onLine}
            >
              Fipen
            </Button>
            <Button
              onClick={() => showFicomScreen()}
              variant={Variant.orange}
              specifiedStyle={{
                marginLeft: '5px',
                fontSize: '16px',
              }}
              icon='file-alt'
              iconWrapperColor={!navigator.onLine || results.postpone ? StyleVariables.neutral500 : StyleVariables.brandAlt600}
              disabled={!navigator.onLine || results.postpone}
            >
              Fiche commerciale
            </Button>
          </div>
          {!navigator.onLine && (
            <p className={styles.OfflineMessage}>Attention, vous devez être connecté pour la génération de fichier.</p>
          )}
          {results.postpone && (
            <p className={styles.OfflineMessage}>
              En cas d’offre régionale ou de report, la fiche commerciale ne peut être éditée.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

ResultsScreen.propTypes = {
  results: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  showFipenScreen: PropTypes.func.isRequired,
  showFicomScreen: PropTypes.func.isRequired,
  equipment: PropTypes.shape().isRequired,
};

export default ResultsScreen;
