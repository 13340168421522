import React from 'react';
import PropTypes from 'prop-types';
import styles from './SimulatorFile.module.scss';

const SimulatorFile = ({ fileLink, title }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
          <p>{title}</p>
      </div>
      <div className={styles.Content}>
        {/*eslint-disable-next-line jsx-a11y/iframe-has-title*/}
        <iframe
          width="100%"
          height="100%"
          src={`https://${fileLink}`}
        />
      </div>
    </div>
  );
};

SimulatorFile.propTypes = {
  fileLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SimulatorFile;
