export const firebaseConfig = {
  apiKey: "AIzaSyBSclxuEwsmDyF_CiRQaEmh8qE4s-F36t0",
  authDomain: "domosimu-prod.firebaseapp.com",
  databaseURL: "https://domosimu-prod.firebaseio.com",
  projectId: "domosimu-prod",
  storageBucket: "domosimu-prod.appspot.com",
  messagingSenderId: "349999588532",
  appId: "1:349999588532:web:880f18c3cb3dcedf3fe3e1",
  measurementId: "G-KJ16T22JV6",
};
