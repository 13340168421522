import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormAmountInput.module.scss';
import { preventNonNumericalInput } from '../../../../helpers/validation';

const FormAmountInput = ({
  label, placeholder, onChange, errorMessage, type, value,
}) => (
  <div className={styles.Wrapper}>
    <div className={styles.Label}>{label}</div>
    <div className={styles.InputWrapper}>
      <input
        type={type}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        className={styles.Input}
        onKeyPress={(event) => preventNonNumericalInput(event)}
        value={value}
      />
      <div className={styles.InputSignWrapper}>
        <div className={styles.InputSign}>€</div>
      </div>
    </div>
    <div className={styles.Error}>{errorMessage}</div>
  </div>
);

FormAmountInput.defaultProps = {
  placeholder: '',
  errorMessage: '',
  type: 'text',
  value: '',
};

FormAmountInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default FormAmountInput;
