import { setNews, setDataIsLoading } from './news.slice';
import { http } from '../../../helpers/http';

export const getData = () => async (dispatch) => {
  dispatch(setDataIsLoading(true));
  const URL = 'news';

  try {
    const response = await http(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_BASE_URL}/${URL}`, 'json',{}, true);
    if (response) {
      dispatch(setNews(response.data.news.map((pieceOfNews) => {
        return {
          ...pieceOfNews
        }
      })));
      dispatch(setDataIsLoading(false));
    }
  } catch (err) {
    dispatch(setDataIsLoading(false));
  }
};
