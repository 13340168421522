import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    json: {},
    isLoading: false,
    parameters: {},
    favoritesMaterials: [],
    updateDate: null,
    isFetch: false,
    simulatorFileLink: null,
    fileIsLoading: false,
    backHistory: '/',
  },
  reducers: {
    setDataJson(state, action) {
      state.json = action.payload;
      state.updateDate = Date.now();
      state.isFetch = !state.isFetch;
    },
    setParameters(state, action) {
      state.parameters = { ...state.parameters, ...action.payload };
      state.updateDate = Date.now();
    },
    setDataIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    addFavoriteMaterial(state, action) {
      const equipment = action.payload;
      const foundAt = state.favoritesMaterials.findIndex(mat => mat.id === equipment.id);
      if (foundAt === -1) {
        state.favoritesMaterials.push(equipment);
      }
    },
    removeFavoriteMaterial(state, action) {
      const equipment = action.payload;
      const foundAt = state.favoritesMaterials.findIndex(mat => mat.id === equipment.id);
      if (foundAt !== -1) {
        state.favoritesMaterials.splice(foundAt, 1);
      }
    },
    clearFavoritesMaterial(state) {
      state.favoritesMaterials = [];
    },
    setDataIsFetch(state) {
      state.isFetch = !state.isFetch;
    },
    setSimulatorFileLink(state, action) {
      state.simulatorFileLink = action.payload;
    },
    setFileIsLoading(state, action) {
      state.fileIsLoading = action.payload;
    },
    setBackHistory(state, action) {
      state.backHistory = action.payload;
    }
  },
});

export const {
  setDataJson,
  setDataIsLoading,
  setParameters,
  addFavoriteMaterial,
  removeFavoriteMaterial,
  setDataIsFetch,
  setSimulatorFileLink,
  setFileIsLoading,
  clearFavoritesMaterial,
  setBackHistory
} = dataSlice.actions;

export default dataSlice.reducer;
