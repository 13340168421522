import { connect } from 'react-redux';
import FicomScreen from '../../Screens/Simulator/FicomScreen';
import { partnerInformationSelector } from '../../store/slices/user/user.selectors';
import { generateFicom } from '../../store/slices/data/data.thunks';
import { dataFileIsLoading } from '../../store/slices/data/data.selectors';

const mapStateToProps = (state) => ({
  dataUser: partnerInformationSelector(state),
  fileIsLoading: dataFileIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  generateFicom: (params) => {
    dispatch(generateFicom(params));
  },
});

const FicomScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FicomScreen);

export default FicomScreenContainer;
