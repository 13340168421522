import { connect } from 'react-redux';
import AuthScreen from '../../Screens/Auth/AuthScreen';
import { authenticateUser } from '../../store/slices/user/user.thunks';
import { setUserIsLoading } from '../../store/slices/user/user.slice';
import { loadParameters } from '../../store/slices/data/data.thunks';
import { setDataJson, clearFavoritesMaterial } from '../../store/slices/data/data.slice';
import {
  userInformationSelector,
  userIsLoadingSelector,
} from '../../store/slices/user/user.selectors';
import {
  appDecorativeImageSelector,
  appInformativeImageSelector,
  donneesPersonnellesSelector,
  informationsCookiesSelector,
  updateDateSelector,
} from '../../store/slices/data/data.selectors';

const mapStateToProps = (state) => {
  return ({
    userInfo: userInformationSelector(state),
    isLoading: userIsLoadingSelector(state),
    decorativeImage: appDecorativeImageSelector(state),
    informativeImage: appInformativeImageSelector(state),
    updateDate: updateDateSelector(state),
    donneesPersonnelle : donneesPersonnellesSelector(state),
    informationsCookie: informationsCookiesSelector(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  submitAuthenticate: (handle, captchaToken) => {
    dispatch(authenticateUser(handle, captchaToken));
  },
  loadParameters: () => {
    dispatch(loadParameters());
  },
  initUserLoader: () => {
    dispatch(setUserIsLoading(false));
  },
  clearData: () => {
    dispatch(setDataJson({}));
    dispatch(clearFavoritesMaterial());
  },
});

const LoginScreenContainer = connect(mapStateToProps, mapDispatchToProps)(AuthScreen);

export default LoginScreenContainer;
