import React from 'react';
import PropTypes from 'prop-types';
import styles from './MenuLink.module.scss';

const MenuLink = ({ active, children, onClick }) => {
  const styleWrapper = [styles.Wrapper];

  if (active) {
    styleWrapper.push(styles.WrapperActive);
  }

  return (
    <div className={styleWrapper.join(' ')}>
      <button
        type="button"
        className={styles.ContentWrapper}
        onClick={() => onClick()}
      >
        {children}
      </button>
    </div>
  );
};

MenuLink.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
};

export default MenuLink;
