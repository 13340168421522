import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionHeader.module.scss';

const SectionHeader = ({ children, specifiedStyle }) => {
  return (
    <div
      className={styles.Wrapper}
      style={{...specifiedStyle}}
    >
      {children}
    </div>
  );
};

SectionHeader.defaultProps = {
  specifiedStyle: null,
};

SectionHeader.propTypes = {
  specifiedStyle: PropTypes.shape(),
};

export default SectionHeader;
