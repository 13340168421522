import React from 'react';
import styles from './LoginInfo.module.scss';

const LoginInfo = () => (
  <div className={styles.Wrapper}>
    <div className={styles.InnerWrapper}>
      <div className={styles.InfoContentWrapper}>
        <p className={styles.InfoContent}>
          Pour utiliser DomoSimu sans connexion,
          un chargement complet à la première utilisation est nécessaire.
          <span className={styles.Emphasis}> Cela peut prendre quelques minutes.</span>
        </p>
      </div>
    </div>
  </div>
);

export default LoginInfo;
