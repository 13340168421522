import React from 'react';
import PropTypes from 'prop-types';
import styles from './Image.module.scss';

const Image = ({ src, alt, customStyles }) => {
  return (
    <img
      alt={alt}
      src={src}
      className={styles.ImageBackground}
      style={{ ...customStyles }}
    />
  )
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  styles: PropTypes.shape(),
};

export default Image;
