import { connect } from 'react-redux';
import { userInformationSelector } from '../../../../store/slices/user/user.selectors';
import { reloadUser } from '../../../../store/slices/user/user.thunks';
import { alertSelector } from '../../../../store/slices/alert/alert.selectors';
import AppRouter from '../../../../Components/modules/router/AppRouter';
import { clearAlert } from '../../../../store/slices/alert/alert.slice';
import {
  updateDateSelector,
  isMaintenanceSelector,
} from '../../../../store/slices/data/data.selectors';
import { loadParameters } from '../../../../store/slices/data/data.thunks';
import { getData } from '../../../../store/slices/news/news.thunks';

const mapStateToProps = (state) => ({
  userInfo: userInformationSelector(state),
  alertInfo: alertSelector(state),
  updateDate: updateDateSelector(state),
  isMaintenance: isMaintenanceSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearAlert: () => {
    dispatch(clearAlert());
  },
  getInfos: (key) => {
    dispatch(reloadUser(key));
    dispatch(getData());
  },
  getParameters: () => {
    dispatch(loadParameters());
  }
});

const AppRouterContainers = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

export default AppRouterContainers;
