import { connect } from 'react-redux';
import NewsScreen from '../../Screens/News/NewsScreen';
import { newsSelector, newsIsLoadingSelector } from '../../store/slices/news/news.selectors';
import { getData } from '../../store/slices/news/news.thunks';

const mapStateToProps = (state) => {
  return ({
    news: newsSelector(state),
    isLoading: newsIsLoadingSelector(state)
  });
};

const mapDispatchToProps = (dispatch) => ({
  getData: () => {
    dispatch(getData());
  },
});

const NewsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsScreen);

export default NewsScreenContainer;
