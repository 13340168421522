import React from 'react';
import PropTypes from 'prop-types';
import ModalBootstrap from 'react-bootstrap/Modal';
import styles from './Modal.module.scss';
import StyleVariables from '../../../assets/StyleVariables';
import { useLocation } from 'react-router-dom';

const Modal = ({ show, onHide, title, children, footer }) => {

  const location = useLocation();

  // Dimension style of modal
  const modalDimStyle = location.pathname === '/login' ||
                        location.pathname === '/donnees-personnelles' ||
                        location.pathname === '/informations-sur-les-cookies'
                        ? 'xl' : 'md';

  // cross button
  const crossButton = location.pathname === '/login'
  ? (
    <div className={styles.RightCross}>
      <button
        type="submit"
        className={styles.CrossButton}
        onClick={() => onHide()}
      >
        <span className={styles.CrossIcon}>
          x
        </span>
      </button>
    </div>
  )
    :
    null;

  return (
    <>
      <style type="text/css">
        {`
          .modal-content {
            border-radius: 30px;
            padding: 20px;
          }
          
          .modal-backdrop {
            width: 100%;
            height: 100%;
          }

          @media screen and ${StyleVariables.mobile} {
            .modal-content {
              padding: 10px;
            }
          }
      `}
      </style>

      <ModalBootstrap
        show={show}
        onHide={onHide}
        size={modalDimStyle}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <ModalBootstrap.Header bsPrefix={styles.ModalHeader}>
            <ModalBootstrap.Title id="contained-modal-title-vcenter">
              {title}
            </ModalBootstrap.Title>
            {crossButton}
          </ModalBootstrap.Header>
          <ModalBootstrap.Body bsPrefix={styles.ModalBody} className={styles.ModalOverview}>
            {children}
          </ModalBootstrap.Body>
          <ModalBootstrap.Footer  bsPrefix={styles.ModalFooter}>
            {footer}
          </ModalBootstrap.Footer>
        </div>

      </ModalBootstrap>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.shape().isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  footer: PropTypes.shape().isRequired,
};

export default Modal;
