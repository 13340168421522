import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormElementWrapper.module.scss';

const FormElementWrapper = ({ children }) => (
  <div className={styles.Wrapper}>
    {children}
  </div>
);

FormElementWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormElementWrapper;
