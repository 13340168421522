import { connect } from 'react-redux';
import {
  dataInformationsSelector,
} from '../../store/slices/data/data.selectors';
import InformationsScreen from '../../Screens/Informations/InformationsScreen';
import { sendMailPdf } from "../../store/slices/data/data.thunks"

const mapStateToProps = (state) => {
  return ({
    informations: dataInformationsSelector(state),
  });
};

const mapDispatchToProps = (dispatch) => ({
  sendMailPdf: (id, email) => {
    dispatch(sendMailPdf(id, email));
  },
});

const InformationsScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InformationsScreen);

export default InformationsScreenContainer;
