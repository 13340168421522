import React from 'react';
import PropTypes from 'prop-types';
import styles from './Item.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Favorite from '../../../Containers/components/modules/simulations/Equipment/Favorite';

const Item = ({
  item,
  expanded,
  type,
  favorite,
  isLast,
  isFromMenu,
  onClick,
  sendMail,
}) => {
  const styleWrapper = [styles.Wrapper];

  if (isFromMenu) {
    styleWrapper.push(styles.WrapperMenu);
  } else {
    if (isLast) {
      styleWrapper.push(styles.BorderRadiusBottom);
    }

    if (expanded) {
      styleWrapper.push(styles.WrapperExpanded);

      if (isLast) {
        styleWrapper.push(styles.WrapperExpandedLast);
      }
    }
  }

  let title = item?.title;
  if (type === 'material') {
    title = item.equipmentName
  }

  let iconItem = '';
  if (type === 'information' && item.type === 'fichier') {
    iconItem = (
      <div
        className={styles.Icon}
        onClick={() => sendMail(item)}
      >
        <FontAwesomeIcon icon="envelope" />
      </div>
    );
  }
  if (type === 'material' && favorite) {
    iconItem = (
      <div className={styles.Icon}>
        <Favorite equipment={item}/>
      </div>
    );
  }

  let sticker = '';
  if (type === 'material') {
    sticker = (
      <div className={styles.LogoWrapper}>
        {item.appicon && item.appsticker && (
          <img
            src={require(`../../../assets/images/eqpstickers/${item.appsticker}`)}
            alt={item.appsticker}
            className={styles.Sticker}
          />
        )}
        {item.appicon && (
          <img
            src={require(`../../../assets/images/eqpicons/${item.appicon}`)}
            alt={item.appicon}
            className={styles.Logo}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styleWrapper.join(' ')}>
      <div
        className={styles.LogoTitle}
        onClick={() => onClick()}
        role="button"
        tabIndex="0"
      >
        {sticker}
        <div className={styles.Title}>
          <p>{title}</p>
        </div>
      </div>
      {iconItem}
    </div>
  );
};

Item.defaultProps = {
  isLast: false,
  isFromMenu: false,
  expanded: false,
  favorite: false,
};

Item.propTypes = {
  item: PropTypes.shape().isRequired,
  expanded: PropTypes.bool,
  isLast: PropTypes.bool,
  favorite: PropTypes.bool,
  isFromMenu: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  sendMail: PropTypes.func,
};

export default Item;
